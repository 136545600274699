import { Validators, FormControl } from '@angular/forms';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { tap, startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { Statut } from 'src/app/entity/Maileva/FilterMaileva';
 
@Component({
  selector: 'app-filters-status',
  templateUrl: './filters-status.component.html',
  styleUrls: ['./filters-status.component.css'],
})
export class FiltersStatusComponent implements OnInit, OnChanges, OnDestroy {
   
  listEtatCourrierSelected: any[] = [];
  listStatutEnvoiSelected: any[] = [];
  filterStatut: Statut = new Statut();
  visible = false;
  selectable = true;
  unsubscribeGetDetailsLibellePost: Subscription;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // Mat Chips Etat Courrier
  etatCourrierFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredEtatCourrier: Observable<any[]>;
  ListEtatCourrier: string[] = [];
  statut: any[] = [];

  @ViewChild('etatCourrierInput') etatCourrierInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoEtatCourrier') matAutocompleteEtatCourrier: MatAutocomplete;

  // Mat Chips Statut Envoi
  statutEnvoiFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredStatutEnvoi: Observable<any[]>;
  ListStatutEnvoi: string[] = [];
  statut_envoi: any[] = [];

  @ViewChild('statutEnvoiInput') statutEnvoiInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoStatutEnvoi') matAutocompleteStatutEnvoi: MatAutocomplete;

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushfilterStatut: EventEmitter<Statut> = new EventEmitter();
  filtre: any;
  constructor(private apiComminucationService: ApiComminucationService, private chRef: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.unsubscribeGetDetailsLibellePost.unsubscribe();
  }

  // Etat Courrier ---------------------------------------------------------------------------------------------

  selectedEtatCourrier(event: MatAutocompleteSelectedEvent): void {
    if (!this.listEtatCourrierSelected.includes(event.option.value)){
      this.ListEtatCourrier.push(event.option.viewValue);
      this.listEtatCourrierSelected.push(event.option.value);
    }

    this.etatCourrierInput.nativeElement.value = '';
    this.etatCourrierFromCtrl.setValue(null);
    this.EmitFilterStatut()
  }

  removeEtatCourrier(etat: string): void {
    const index = this.ListEtatCourrier.indexOf(etat);
    if (index >= 0) {
      this.ListEtatCourrier.splice(index, 1);
      this.listEtatCourrierSelected.splice(index, 1);
    }
  }

  private _filterEtatCourrier(): any[] {
    let filterValue = this.etatCourrierFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.statut.filter((etat) => etat.libelle.toLowerCase().includes(filterValue) );
  }

  // Statut Envoi ---------------------------------------------------------------------------------------------

  selectedStatutEnvoi(event: MatAutocompleteSelectedEvent): void {
    this.ListStatutEnvoi.push(event.option.viewValue);
    this.listStatutEnvoiSelected.push(event.option.value);
    this.statutEnvoiInput.nativeElement.value = '';
    this.statutEnvoiFromCtrl.setValue(null);
  }

  removeStatutEnvoi(statutEnvoi: string): void {
    const index = this.ListStatutEnvoi.indexOf(statutEnvoi);
    if (index >= 0) {
      this.ListStatutEnvoi.splice(index, 1);
      this.listStatutEnvoiSelected.splice(index, 1);
    }
  }

  private _filterStatutEnvoi(): any[] {
    let filterValue = this.statutEnvoiFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.statut_envoi.filter((statutEnvoi) => statutEnvoi.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    this.filtre = localStorage.getItem('filtre');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
    this.filterStatut = this.filtre?.statut
  }
    this.getInformationDetailsLibelle();
    this.filteredEtatCourrier = this.etatCourrierFromCtrl.valueChanges.pipe(
      startWith(null),
      map((etat: string | null) => (etat ? this._filterEtatCourrier() : this.statut.slice()))
    );

    this.filteredStatutEnvoi = this.statutEnvoiFromCtrl.valueChanges.pipe(
      startWith(null),
      map((statutEnvoi: string | null) => (statutEnvoi ? this._filterStatutEnvoi() : this.statut_envoi.slice()))
    );
  }

  getInformationDetailsLibelle() {
    this.unsubscribeGetDetailsLibellePost = this.apiComminucationService.GetDetailsLibellePost().subscribe((data) => {
      this.statut = data;
      if (this.filtre) {
        if (this.filterStatut?.etat_courrier.length > 0) {
          this.ListEtatCourrier = this.statut
            .filter(statut => this.filterStatut.etat_courrier.includes(statut.id))
            .map(statutAff => {
              this.listEtatCourrierSelected.push(statutAff.id);
              this.EmitFilterStatut()
              return statutAff.libelle
            });
        }}
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetStatutForm(changes.Reset.currentValue);
  }

  resetStatutForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListEtatCourrier = [];
      this.filterStatut.etat_courrier = this.listEtatCourrierSelected = [];
      this.ListStatutEnvoi = [];
      this.filterStatut.statut_envoie = this.listStatutEnvoiSelected = [];
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushfilterStatut.emit(this.filterStatut);
    }
  }

  EmitFilterStatut() {
    this.filterStatut.etat_courrier = this.listEtatCourrierSelected;
    this.filterStatut.statut_envoie = this.listStatutEnvoiSelected;
    this.onPushfilterStatut.emit(this.filterStatut);
  }
}
