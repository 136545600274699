import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { FilterHistoryService } from 'src/app/services/ApiFilterSave/filter-history.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { ListCollection } from 'src/app/entity/filterSave';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as internal from 'events';
import { FilterOpportunite, ListFilterCollection } from 'src/app/entity/Opportunity/FilterOpportunite';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface CardItem {
  title: string;
  subTitle: string;
  subSubTitle: string;
  checked: boolean;
  id: number;
}

@Component({
  selector: 'app-list-filter-saved',
  templateUrl: './list-filter-saved.component.html',
  styleUrls: ['./list-filter-saved.component.css'],
})
export class ListFilterSavedComponent implements OnInit {
  @Input() entity;
  @Output() filterOpportunite = new EventEmitter<FilterOpportunite>();
   
  checkedcollectionId: number = null;
  Libellefiltre: string = '';
  LibelleCollection: string = '';
  search: string = '';
  checkAddCollection: boolean = false;
  isClickEnabled: boolean = true;
  isDropdownVisible = true;
  showActions: boolean = false;
  listCollection: ListCollection;
  ListFilterCollection: ListFilterCollection;
  step: string = '1';
  currentHoveredIndex: number | null = null;
  checkedCollectionLibelle: string = '';
  checkedCollectionid: number = null;
  sSearch: string = '';
  SsearchFilter: string = '';
  showLaoder: boolean = false;
  showLaoder1: boolean = false;
  isEditable = false;
  collectionName: string = '';
  @Output() reloadParentEvent = new EventEmitter<void>();
  alerts: any;
  buttons: any;
  constructor(
    private apiFIlterHistoryService: FilterHistoryService,
    private notificationsService: NotificationsService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService

  ) {}

  ngOnInit(): void {
    this.translateService.get('languages').subscribe((object: any) => {
      this.alerts=object.alerts
      this.buttons=object.buttons
    });
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('languages').subscribe((object: any) => {
        this.alerts=object.alerts
      this.buttons=object.buttons
      });
     });
    this.showLaoder = true;
    this.getListCollection(this.entity, this.sSearch);
  }

  stopClickPropagation(event: Event) {
    event.stopPropagation();
  }

  showhidePop() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  showhidePopfirst() {
    this.step = '1';
    if (this.isDropdownVisible === false) {
      this.isDropdownVisible = true;
    }
    this.getListCollection(this.entity, this.sSearch);
  }

  checkcollection(i) {
    this.listCollection.collections[i].checked = !this.listCollection.collections[i].checked;
    for (let j = 0; j < this.listCollection.collections.length; j++) {
      if (j !== i) {
        this.listCollection.collections[j].checked = false;
      }
    }

    if (this.listCollection.collections[i].checked === true) {
      this.checkedcollectionId = this.listCollection.collections[i].id;
      this.isClickEnabled = false;
    } else {
      this.isClickEnabled = true;

      this.checkedcollectionId = null;
    }
  }

  AddFilterCollection() {
    if (this.LibelleCollection !== '' && this.LibelleCollection !== null && this.checkedcollectionId === null) {
      const unsubscribeFilterSave = this.apiFIlterHistoryService
        .addFilterCollection(this.entity, this.LibelleCollection)
        .subscribe(
          (response) => {
            this.checkedcollectionId = response.id;
          },
          (error) => {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.Operationechoue;
            notificationMessage.message = error.error.message;
            this.notificationsService.sendMessage(notificationMessage);
          }
        );
    }
  }

  getListCollection(entity, sSearch) {
    const unsubscribeFilterSave = this.apiFIlterHistoryService.getListCollection(entity, sSearch).subscribe(
      (response: ListCollection) => {
        this.listCollection = response;
        this.showLaoder = false;
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  getFilterListCollection(entity, collection, SsearchFilter) {
    this.ListFilterCollection = null;
    const unsubscribeFilterSave = this.apiFIlterHistoryService
      .getFiltreCollection(entity, collection, SsearchFilter)
      .subscribe(
        (response: ListFilterCollection) => {
          this.ListFilterCollection = response;
          this.showLaoder1 = false;
        },
        (error) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = error.error.message;
          this.notificationsService.sendMessage(notificationMessage);
        }
      );
  }

  expandcollectionName(name: string): string {
    if (name.length < 15) {
      return name;
    } else {
      return name.substring(0, 14) + '...';
    }
  }

  oneditClick(item) {
    this.showLaoder1 = true;
    this.checkedCollectionLibelle = item.libelle;
    this.checkedCollectionid = item.id;
    this.step = '2';
    this.getFilterListCollection(this.entity, item.id, this.SsearchFilter);
  }

  retourCollection() {
    this.checkedCollectionLibelle = '';
    this.checkedCollectionid = null;
    this.step = '1';
  }

  setHoveredIndex(index: number | null): void {
    this.currentHoveredIndex = index;
  }

  filterlance(filter) {
    localStorage.removeItem('savedFilter');
    localStorage.removeItem('savedCollection');
    localStorage.removeItem('filter');
    const currentUrlTree = this.router.parseUrl(this.router.url);
    const queryParams = {
      filter: filter.id,
      collection: this.checkedcollectionId,
    };
    localStorage.setItem('savedFilter', filter.id.toString());
    localStorage.setItem('savedCollection', this.checkedcollectionId.toString());
    const newUrlTree = this.router.createUrlTree([], {
      queryParams: { ...currentUrlTree.queryParams, ...queryParams },
    });

    this.router.navigateByUrl(newUrlTree);

    this.reloadParentEvent.emit();
  }

  getDataValue(event) {
    if (event.target.value.length > 2 || event.target.value.length === 0) {
      this.sSearch = event.target.value;
      this.getListCollection(this.entity, this.sSearch);
    }
  }

  getDataValuefilter(event) {
    if (event.target.value.length > 2 || event.target.value.length === 0) {
      this.SsearchFilter = event.target.value;

      this.getFilterListCollection(this.entity, this.checkedCollectionid, this.SsearchFilter);
    }
  }

  DeleteCollection(idCollection) {
    if (idCollection !== '' && idCollection !== null) {
      const unsubscribeFilterSave = this.apiFIlterHistoryService.deleteCollection(idCollection).subscribe(
        (response) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = this.alerts.Successoperation;
          notificationMessage.message = response.message;
          this.notificationsService.sendMessage(notificationMessage);
          this.getListCollection(this.entity, this.sSearch);
          this.step = '1';
        },
        (error) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title =this.alerts.Operationechoue;
          notificationMessage.message = error.error.message;
          this.notificationsService.sendMessage(notificationMessage);
        }
      );
    }
  }

  DeleteFilter(libellefilter, idCollection) {
    if (libellefilter !== '' && libellefilter !== null && idCollection !== '' && idCollection !== null) {
      const unsubscribeFilterSave = this.apiFIlterHistoryService
        .deleteFilter(libellefilter, idCollection, this.entity)
        .subscribe(
          (response) => {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.success;
            notificationMessage.title = this.alerts.Successoperation;
            notificationMessage.message = response.message;
            this.notificationsService.sendMessage(notificationMessage);
            this.getFilterListCollection(this.entity, this.checkedCollectionid, this.SsearchFilter);
            this.getListCollection(this.entity, this.sSearch);
          },
          (error) => {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.Operationechoue;
            notificationMessage.message = error.error.message;
            this.notificationsService.sendMessage(notificationMessage);
          }
        );
    }
  }

  makeEditable(nameCollection) {
    this.isEditable = true;
    this.collectionName = nameCollection;
  }

  makeNonEditable() {
    this.isEditable = false;
    this.checkedCollectionLibelle = this.collectionName;
    if (
      this.checkedCollectionLibelle !== '' &&
      this.checkedCollectionLibelle !== null &&
      this.checkedcollectionId !== null
    ) {
      const unsubscribeFilterSave = this.apiFIlterHistoryService
        .updateCollection(this.checkedcollectionId, this.checkedCollectionLibelle, this.entity)
        .subscribe(
          (response) => {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.success;
            notificationMessage.title = this.alerts.Successoperation ;
            notificationMessage.message = response.message;
            this.notificationsService.sendMessage(notificationMessage);

            this.getListCollection(this.entity, this.sSearch);
          },
          (error) => {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.Operationechoue;
            notificationMessage.message = error.error.message;
            this.notificationsService.sendMessage(notificationMessage);
          }
        );
    }
  }
}
