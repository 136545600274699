import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Expediteur } from 'src/app/entity/Maileva/FilterMaileva';


@Component({
  selector: 'app-filters-expiditeur',
  templateUrl: './filters-expiditeur.component.html',
  styleUrls: ['./filters-expiditeur.component.css']
})
export class FiltersExpiditeurComponent implements OnInit, OnChanges {
  filterExpediteur: Expediteur = new Expediteur()
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushfilterExpiditeur: EventEmitter<Expediteur> = new EventEmitter();
  filtre: any;
  constructor(private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.filtre = localStorage.getItem('filtre');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
    this.filterExpediteur = this.filtre?.expediteur
    this.EmitFilterExpiditeur()
  }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetExpediteurForm(changes.Reset.currentValue);
  }

  resetExpediteurForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.filterExpediteur.adresse_expediteur = "";
      this.filterExpediteur.complement_adresse_expediteur = "";
      this.filterExpediteur.nom_prenom_expediteur = "";
      this.filterExpediteur.ville_expediteur = "";
      this.filterExpediteur.cp_expediteur = "";

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushfilterExpiditeur.emit(this.filterExpediteur);
    }
  }
  
  EmitFilterExpiditeur() {

    this.onPushfilterExpiditeur.emit(this.filterExpediteur);
  }
}
