import { Component, OnInit, Optional } from '@angular/core';
import { ApiAuthentificationService } from '../services/ApiAuthentification/api-authentification.service';
import { LoginService } from '../services/login/login.service';
import { User } from '../entity/User/User';
import { Configuration } from '../services/configuration';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationMessage, NotificationType } from '../entity/NotificationMessage';
import { NotificationsService } from '../shared/NotificationsService/notifications.service';
import { Organisme } from '../pages/parametres/gestion-campagnes/fiche-campagnes/fiche-campagnes.component';
import { user } from '../entity/User/UserAdmin2';
import { AccessList } from '../entity/accessList';
import { TranslateService } from '@ngx-translate/core';

export class UserLogin {
  Acces_Token: string;
  active: boolean;
  email: string;
  error: boolean;
  id_role: string;
  id_user: string;
  id: string;
  nom: string;
  prenom: string;
  mobile: string;
  user: any;
  acces_geoprod: any;
  entreprise_id: string;
  transaction_financiere: number;

  public load() {
    this.Acces_Token = localStorage.getItem('Acces_Token');
    this.email = localStorage.getItem('email');
    this.id_role = localStorage.getItem('id_role');
    this.id_user = localStorage.getItem('id_user');
    this.nom = localStorage.getItem('nom');
    this.prenom = localStorage.getItem('prenom');
    this.entreprise_id = localStorage.getItem('entreprise_id');
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  password_type = 'password';
  mail: string;
  password: string;
  error = '';
  currentRoute: string = '/';
  previousUrl: string;
  show_loading_add: boolean = false;
  submitted = false;
  apiKey: string;
  loginForm: FormGroup;
  colors: any = {
    accent: 'accent',
    warn: 'warn',
    primary: 'primary',
  };

  accessListe: AccessList;
  alerts: any;
  buttons: any;
  // checked = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiAuthentificationService: ApiAuthentificationService,
    private loginService: LoginService,
    private notificationsService: NotificationsService,
    private configuration: Configuration,
    private translate: TranslateService
  ) {
    this.apiKey = environment.api_key;
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
      });
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      stayConnected: [true],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  togglePassword() {
    if (this.password_type === 'password') {
      this.password_type = 'text';
    } else {
      this.password_type = 'password';
    }
  }

  saveData(data) {
    localStorage.setItem('Acces_Token', data.Acces_Token);
    localStorage.setItem('email', data.email);
    localStorage.setItem('id_role', data.id_role);
    localStorage.setItem('id_user', data.id_user);
    localStorage.setItem('nom', data.nom);
    localStorage.setItem('prenom', data.prenom);
    localStorage.setItem('entreprise_id', data?.user?.entreprise_id);
  }

  login() {
    this.submitted = true;
    this.show_loading_add = true;
    if (this.loginForm.invalid) {
      this.error = 'Champs vide !';
      this.show_loading_add = false;
      return;
    }
    this.apiAuthentificationService.loginGet(this.f.email.value, this.f.password.value, this.apiKey).subscribe(
      (data: UserLogin) => {
        this.apiAuthentificationService.resetSessionTimer();
        localStorage.setItem('apiKey', data.user.api_key);
        this.configuration.apiKeys.idSession = data.Acces_Token;
        this.apiAuthentificationService.setLoginData(data);
        this.apiAuthentificationService.GetAccessList().subscribe((data) => {
          this.accessListe = data;
          this.apiAuthentificationService.setAccessListe(this.accessListe);
        });

        if (data.active && !data.error) {
          if (data.acces_geoprod === 0) {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.problemLogIn;
            notificationMessage.message = this.alerts.acessDeniedGeoprod;
            this.notificationsService.sendMessage(notificationMessage);
            this.show_loading_add = false;
          } else {
            this.saveData(data);
            this.configuration.setLogin(data);
            this.currentRoute = localStorage.getItem('currentRoute');
            if (localStorage.getItem('currentRoute') === null || localStorage.getItem('currentRoute') === '/login') {
              this.router.navigate(['/']);
              this.show_loading_add = false;
            } else {
              this.router.navigate([localStorage.getItem('currentRoute')]);
              this.show_loading_add = false;
            }
          }
        } else {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.problemLogIn;
          notificationMessage.message = this.alerts.accountNotActivated;
          this.show_loading_add = false;
          this.notificationsService.sendMessage(notificationMessage);
        }
      },
      () => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.problemLogIn;
        this.notificationsService.sendMessage(notificationMessage);
        this.show_loading_add = false;
      }
    );
  }
}
