import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { ApiAdminService } from 'src/app/services/ApiAdmin/api-admin.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { ListOrganisme } from 'src/app/entity/Generic/ListOrganisme';
import { ApiOrganismeService } from 'src/app/services/ApiOrganisme/api-organisme.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { element } from 'protractor';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-list-organisme',
  templateUrl: './list-organisme.component.html',
  styleUrls: ['./list-organisme.component.css'],
})
export class ListOrganismeComponent implements OnInit, OnDestroy, AfterViewInit {
  listeOfUnsbscribeWebService: Subscription[] = [];
   
  listOrganisme: ListOrganisme[] = [];
  dataTable: any;
  miniListing: any;
  alerts: any;
  private unsubscribeGetListOrganisme: Subscription;
  buttons: any;
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private routes: Router,
    private apiAdminServices: ApiAdminService,
    private apiOrganismeService: ApiOrganismeService,
    private chRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}



  ngOnDestroy(): void {
    // this.listeOfUnsbscribeWebService.forEach((element) => {
    //   element.unsubscribe();
    // });
    if (this.unsubscribeGetListOrganisme) {
      this.unsubscribeGetListOrganisme.unsubscribe();
    }
  }

  ngAfterViewInit(): void {

    this.chRef.detectChanges();
  }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionOrganisme);
      this.miniListing = object.miniListing;
      this.alerts = object.alerts;
      this.buttons = object.buttons;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionOrganisme);
        this.miniListing = object.miniListing;
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.detectChangedb();
      });
    });
    this.getListOrganisme();
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  retourToList() {
    this.routes.navigate(['/gestion-organisme']);
  }

 async getListOrganisme() {
    this.showloader();
    if (this.unsubscribeGetListOrganisme) {
      this.unsubscribeGetListOrganisme.unsubscribe();
    }
     this.unsubscribeGetListOrganisme = await this.apiOrganismeService
      .GetListOrganisme()
      .subscribe((data: ListOrganisme[]) => {
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.listOrganisme = data;

        this.detectChangedb();
        this.hideloader();
      });

    this.listeOfUnsbscribeWebService.push(this.unsubscribeGetListOrganisme);

  }

  detectChangedb(){
    this.chRef.detectChanges();
    const table: any = $('#Table');
    this.dataTable = table.DataTable({
      destroy: true,
      stateSave: true,
      pagingType: 'full_numbers',
      language: {
        sProcessing: this.miniListing.traitement,
        searchPlaceholder: this.miniListing.search,
        sSearch: '<p aria-hidden="true"></p>',
        sLengthMenu: this.miniListing.Afficher + '_MENU_' + this.miniListing.element,
        sInfo:
          this.miniListing.displayingElement +
          '_START_' +
          this.miniListing.label_a +
          '_END_' +
          this.miniListing.label_sur +
          '_TOTAL_' +
          this.miniListing.element,
        sInfoEmpty: this.miniListing.sInfoEmpty,
        sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
        sInfoPostFix: '',
        sLoadingRecords: this.miniListing.chargement,
        sZeroRecords: this.miniListing.noRecords,
        sEmptyTable: this.miniListing.emptyTable,
        oPaginate: {
          sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
          sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
          sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
          sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
        },
        oAria: {
          sSortAscending: this.miniListing.sSortAscending,
          sSortDescending: this.miniListing.sSortDescending,
        },
        select: {
          rows: {
            _: '%d' + this.miniListing.selectedLines,
            0: this.miniListing.zeroselected,
            1: this.miniListing.oneselected,
          },
        },
      },
    });
  }

  alertConfirmUpdateActive(idOrg, status) {
    if( status ===0){
     swal
       .fire({
         title:this.alerts.confirmClosingSubOrganisme,
         icon: 'info',
         showConfirmButton: true,
         showCloseButton: true,
         showCancelButton: true,
         cancelButtonText: this.buttons.fermer,
         confirmButtonText: this.buttons.confirmer,
         confirmButtonColor: '#28a8d2',
       })
       .then((result) => {
         if (result.isConfirmed) this.changeStatus(idOrg, status);
                 });
    }else {
     this.changeStatus(idOrg, status);
    }
     
   }

   alertError( err?) {
    swal.fire({
      title: this.alerts.errorUpdatingOrg,
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }


  changeStatus(idOrg: string, status: number) {
    this.showloader()
    this.apiAdminServices.ActivateDeactivateOrganisme(idOrg, status).subscribe(
      (response) => {
        this.hideloader();
        this.toastr.success(this.alerts.Successoperation);
        // window.location.reload()
        this.getListOrganisme();
      },
      (error) => {
        this.hideloader();
        this.alertError(error.error.message);
      }
    );
  }
}
