import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiGarantieService } from 'src/app/services/ApiGarantie/api-garantie.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-garanties',
  templateUrl: './list-garanties.component.html',
  styleUrls: ['./list-garanties.component.css'],
})
export class ListGarantiesComponent implements OnInit, AfterViewInit {
  garantie: any;
   
  id: string = '';
  dataTable: any;
  unsubscribeGetListGarantie: Subscription;
  miniListing: any;
  currentLanguage: string;
  constructor(
    private routes: Router,
    private apiGarantieService: ApiGarantieService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private chRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
        this.chRef.detectChanges();
  }

  ngOnInit(): void {
     this.showloader();
     this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionGarantie);
      this.miniListing = object.miniListing;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionGarantie);
        this.miniListing = object.miniListing;
       this. detectChangesDt();
      });
    });

    this.listGarantie();
  }

  listGarantie() {
    this.unsubscribeGetListGarantie = this.apiGarantieService.getListGarantie().subscribe((Response: any) => {
      this.garantie = Response;
      this.detectChangesDt();
      this.hideloader();
    });
  }

  detectChangesDt(){
    this.chRef.detectChanges();
      const table: any = $('#Table');
      this.dataTable = table.DataTable({
        destroy: true,
        stateSave: true,
        pagingType: 'full_numbers',
        language: {
          sProcessing: this.miniListing.traitement,
          searchPlaceholder: this.miniListing.search,
          sSearch: '<p aria-hidden="true"></p>',
          sLengthMenu: this.miniListing.Afficher + '_MENU_' + this.miniListing.element,
          sInfo:
            this.miniListing.displayingElement +
            '_START_' +
            this.miniListing.label_a +
            '_END_' +
            this.miniListing.label_sur +
            '_TOTAL_' +
            this.miniListing.element,
          sInfoEmpty: this.miniListing.sInfoEmpty,
          sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
          sInfoPostFix: '',
          sLoadingRecords: this.miniListing.chargement,
          sZeroRecords: this.miniListing.noRecords,
          sEmptyTable: this.miniListing.emptyTable,
          oPaginate: {
            sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
            sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
            sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
            sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
          },
          oAria: {
            sSortAscending: this.miniListing.sSortAscending,
            sSortDescending: this.miniListing.sSortDescending,
          },
          select: {
            rows: {
              _: '%d' + this.miniListing.selectedLines,
              0: this.miniListing.zeroselected,
              1: this.miniListing.oneselected,
            },
          },
        },
      });
  }


  goTofiche() {
    this.routes.navigate(['/add-garantie/nouveau']);
  }

  goToDetails(item: any) {
    this.routes.navigate(['/add-garantie/' + item.code]);
  }



  expandFileName(name: string): string {
    if (name.length < 12) {
      return name;
    } else {
      return name.substring(0, 11) + '...';
    }
  }
  
  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
