<div class="flex-column bg-white rounded-card">
    <div class="header-card-gestion py-3">
      <div class="col-3">
      <button type="button" class="btn-dt-add ml-4" routerLink="fiche/nouveau"  [attr.data-title]="'languages.organisme.addOrganisme' | translate">
        <i class="fa-solid fa-plus "></i>
      </button>
    </div></div>
    <div class="p-3 mb-3">
      <table id="Table" class="table">
        <thead class="head-table">
          <tr>
            <th>#</th>
            <th>  {{"languages.affaire.name" | translate}}</th>
            <th> {{"languages.listing.Active" | translate}}</th>
            <th> {{"languages.listing.Type" | translate}}</th>
            <th> {{"languages.listing.Statut" | translate}}</th>
            <th> {{"languages.listing.actions" | translate}}</th>
          </tr>
        </thead>
        <tbody class="body-table">
          <tr  *ngFor="let organisme of listOrganisme; let index = index">
            <td>{{ index+1 }}</td>
            <td>{{ organisme.nom }}</td>
            <td >
              <img src="/assets/icons/check-circle.svg" *ngIf=" organisme.active ===1">
              <img src="/assets/icons/times-icon.svg" *ngIf=" organisme.active ===0">
            </td>
            <td>{{organisme.type}}</td>
            <td>        
              <button  type="button" class="btn-dt-desactiver" *ngIf="organisme.active == '1'" (click)="alertConfirmUpdateActive(organisme.id,0)">
                <i class="fa-solid fa-lock fa-fw"></i>
                 {{"languages.listing.Desactiver" | translate}}
              </button>
            <button  type="button" class="btn-dt-activer"  *ngIf="organisme.active == '0'" (click)="alertConfirmUpdateActive(organisme.id,1)">
              <i class="fa-solid fa-unlock-keyhole fa-fw"></i>
                 {{"languages.listing.Activer" | translate}}
            </button>
        
            </td>
           <td>
            <button type="button" class="btn-dt-list" routerLink="fiche/{{organisme.id}}">
              <img src="assets/icons/edit-icon.svg">
             </button>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
