import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Compagnie } from 'src/app/entity/Compagnie';
 
import * as $ from 'jquery';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-gestion-companies',
  templateUrl: './gestion-companies.component.html',
  styleUrls: ['./gestion-companies.component.css'],
})
export class GestionCompaniesComponent implements OnInit {
  miniListing: any;
  currentLanguage: string;
  constructor(
    private chRef: ChangeDetectorRef,
    private apiCampagniesService: ApiCampagniesService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private translate: TranslateService
  ) {}

   
  Companies: Compagnie[] = [];
  dataTable: any;
  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionCompagnies);
      this.miniListing = object.miniListing;
    });


    this.translate.onLangChange.subscribe((data) => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionCompagnies);
        this.miniListing = object.miniListing;
        this.detectChangesDt();
      });
    });
    this.getListCompanies();
  }

  getListCompanies() {
    this.showloader();
    this.apiCampagniesService.getListCompagniesDetails().subscribe((Response: Compagnie[]) => {
      this.Companies = Response;
     this.detectChangesDt();
      this.hideloader();
    });
  }

  detectChangesDt(){
    this.chRef.detectChanges();
      const table: any = $('#Table');
      this.dataTable = table.DataTable({
        destroy: true,
        stateSave: true,
        pagingType: 'full_numbers',
        language: {
          sProcessing: this.miniListing.traitement,
          searchPlaceholder: this.miniListing.search,
          sSearch: '<p aria-hidden="true"></p>',
          sLengthMenu: this.miniListing.Afficher + '_MENU_' + this.miniListing.element,
          sInfo:
            this.miniListing.displayingElement +
            '_START_' +
            this.miniListing.label_a +
            '_END_' +
            this.miniListing.label_sur +
            '_TOTAL_' +
            this.miniListing.element,
          sInfoEmpty: this.miniListing.sInfoEmpty,
          sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
          sInfoPostFix: '',
          sLoadingRecords: this.miniListing.chargement,
          sZeroRecords: this.miniListing.noRecords,
          sEmptyTable: this.miniListing.emptyTable,
          oPaginate: {
            sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
            sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
            sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
            sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
          },
          oAria: {
            sSortAscending: this.miniListing.sSortAscending,
            sSortDescending: this.miniListing.sSortDescending,
          },
          select: {
            rows: {
              _: '%d' + this.miniListing.selectedLines,
              0: this.miniListing.zeroselected,
              1: this.miniListing.oneselected,
            },
          },
        },
      });
  }


  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
