<div id="tags" class="mt-2">
    <form [formGroup]="formTag" class="example-form">
        <div class="field-search-tag border-0 p-3 bg-white">
          <p class="form-group-container-title">{{ "languages.groupedactions.mots_cles" | translate}}</p>

            <mat-form-field class="full-width">
                <ng-container *ngIf="loaderListTags  == true">
                    <div class="spinner-recherche">
                        <mat-spinner [diameter]="15"></mat-spinner>
                    </div>
                </ng-container>
                <mat-label>
<i class="fa-solid fa-magnifying-glass" style="color: rgb(110, 108, 108);padding-right:10px"></i>
                     {{ "languages.buttons.rechercher" | translate}}
                </mat-label>
                <input formControlName="tagText" type="text" matInput [matAutocomplete]="auto">
                <input formControlName="tagTextId" type="text" hidden>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <ng-container *ngIf="!notFoundTags">
                        <mat-option (onSelectionChange)="onSelectTag(item)"
                            *ngFor="let item of tagAutoComplete$ | async; let index = index" [value]="item.libelle">
                            {{item.libelle}}
                        </mat-option>
                    </ng-container>
                    <mat-option *ngIf="(tagAutoComplete$ | async)?.length == 0 && notFoundTags &&
            formTag.value.tagText != ''" disabled>
                         {{ "languages.groupedactions.mots_cles.Aucun" | translate}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <!--      <ng-container *ngIf="loaderActiveTags && !appear ">
                <div class="d-flex justify-content-center">
                    <mat-spinner [diameter]="25"></mat-spinner>
                </div>
            </ng-container> -->
            <ng-container *ngIf="appear">
                <ng-container *ngIf="activeTags.length > 0">
                    <div class="tags-box" id="ex2">
                            <mat-chip-set 
                            class="example-chip "
                            >
                            <mat-chip 
                              class="example-box mr-2 chips " 
                              cdkDrag [ngStyle]="{'background-color':tag.couleur_background, 'color':tag.couleur}"
                              *ngFor="let tag of activeTags; let index = index ; let first = first;" 
                              [ngClass]=" {'dynamic-width-chip': true, 'mt-2': first, 'mt-1': !first}">
                              {{tag.libelle}}
                              <i class="fa-solid fa-circle-xmark fa-lg ml-2 remove-tag" [ngClass]="{'cover':!this.isReadOnly}" (click)="removeTag(index)"></i>
                            </mat-chip>
                          </mat-chip-set>
    
                    </div>
                </ng-container>
                <ng-container *ngIf="activeTags.length < 1">
                    <p> {{ "languages.groupedactions.mots_cles_Pasdesmots" | translate}}</p>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!appear">
                <ng-container *ngIf="list.length > 0">
                    <div class="tags-box">
                        <label *ngFor="let tag of list; let index = index" class="tag-active d-inline-flex m-1 p-2"
                            [ngStyle]="{'background-color':tag.couleur_background, 'color':tag.couleur}">
                            {{tag.libelle}}
                            <i class="fa-solid fa-circle-xmark fa-lg ml-2 remove-tag" (click)="deleteTags(index)"></i>
                        </label>
                    </div>
                </ng-container>

            </ng-container>
        </div>
    </form>
</div>
