import { environment } from 'src/environments/environment';
import { BankInformation } from './../../../../../../entity/BankInformation';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
 
import { Prospect } from '../../../../../../entity/Prospect';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import { ApiProspectsService } from '../../../../../../services/ApiProspects/api-prospects.service';
import { AutoFillEntity } from '../../../../../../entity/Opportunity/AutoFillEntity';
import { Country } from '@angular-material-extensions/select-country';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_FORMATS } from '././../../../../../../shared/Utils/dateAdapter';
import { debounceTime, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FR as France } from '../prospect-pro-form/json-dict/fr';
import { TN as Tunis } from '../prospect-pro-form/json-dict/tn';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { BodyConf, chercheBody } from '../prospect-pro-form/prospect-pro-form.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ProspectCLientService } from 'src/app/services/prospect-client.service';
import { ProspectFiltredListModalComponent } from '../prospect-filtred-list-modal/prospect-filtred-list-modal/prospect-filtred-list-modal.component';
class formbuilder {
  data;
  schema;
}
class FilterBody {
  name: any = '';
  surname: any = '';
  mobile: any = '';
  info_com: {} = {};
}

@Component({
  selector: 'app-prospect-form',
  templateUrl: './prospect-form.component.html',
  styleUrls: ['./prospect-form.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ProspectFormComponent implements OnInit, OnChanges, OnDestroy {
  visible = true;
  selectable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  longitude: number;
  latitude: number;
  isfilled = false;
  loaderinfo = true;
  dateNow: Date = new Date();
  // -------------------------------------------------------------------------------------------------
   
  isLoadingTitre: boolean = true;
  AdresseSaisieAuto: string = '';
  AdresseComplement: string = '';
  Civilite = [
    { id: 'M.', nom: 'M.' },
    { id: 'Mme.', nom: 'Mme.' },
  ];

  SituationFamiliale = [{ id: 'Marie', nom: 'Marie(é)' }];

  isLoadingFormeJuridique: boolean = true;
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nom',
    selectAllText: 'Selectionner tout',
    unSelectAllText: 'Déselectionner tout',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  listUnsubscribe: Subscription[] = [];
  @Input() typeEntity? = '';
  @Input() index: string = '';
  @Input() prospect: Prospect = new Prospect();
  prospectClone: Prospect = new Prospect();
  @Input() type: number = 0;
  @Input() isMainProspect: boolean = true;
  @Input() prospectForm: FormGroup;
  @Input() modeupdateProspect?: string;
  @Input() isRequired: boolean;
  @Output() ProspectExport: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateProspect: EventEmitter<Prospect> = new EventEmitter();
  @Output() onSelectToFillOldProspect: EventEmitter<string> = new EventEmitter();
  @Input() ticket;
  @Input() formProspectChild;
  @Input() formMainProspect;
  model: any = {};
  model_copy: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  control = new FormControl();
  telCountry = environment.country;
  countryLanguage: any;
  form = new FormGroup({});
  data: any = {};
  keyFormProspect = environment.keyFormProspect;
  prospectFormnew: formbuilder = new formbuilder();
  public href: string = '';
  loadingFormBuilder: boolean = false;
  @Input() allowed_to_update: boolean;
  @Input() schemaValue: any;
  alerts: any;
  buttons: any;
  dialogRef: MatDialogRef<ProspectFiltredListModalComponent, any>;
  listkeys: BodyConf[] = [
    { name: 'name', title: 'Nom', disabled: false },
    { name: 'surname', title: 'Prénom', disabled: false },
    { name: 'mobile', title: 'Téléphone', disabled: false },
  ];

  filterBody: FilterBody = new FilterBody();
  selectedValueOpp: string;
  selectedValue: string;
  selectedKey: string = '';
  @Input() modeAjout = false;
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private apiProspectsService: ApiProspectsService,
    private formlyJsonschema: FormlyJsonschema,
    private translate: TranslateService,
    private prospectCLientService: ProspectCLientService,
    private dialog: MatDialog,
    private apiConfigurationService: ApiConfigurationService
  ) {}

  ngAfterContentInit(): void {}

  ngOnDestroy(): void {
    this.listUnsubscribe.forEach((element) => {
      element.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.prospect?.currentValue) {
      if (!this.prospect.form || !this.prospect.form.schema || Object.keys(this.prospect.form.schema).length === 0) {
        this.loaderinfo = false;
      }

      if (!this.prospectForm) {
        this.initFormProspect();
        // this.buildForm();
      }
    }
    if (changes?.schemaValue?.currentValue?.schema) {
      this.prospectFormnew = changes?.schemaValue?.currentValue;
      this.buildForm();

      // if (this.fields[0].fieldGroup.length > 0) {
      //   this.isfilled = true;
      //   this.loaderinfo = false;
      // } else {
      //   this.loaderinfo = false;
      // }
    }
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });

    this.keyFormProspect = environment.keyFormProspect;
    this.getConfigurationForm();
    this.formInfoHolder.push(new chercheBody());
    this.formGroup = this.formBuilder.group({
      nomprenom: new FormControl('', Validators.minLength(3)),
      telephone: new FormControl('', Validators.minLength(3)),
      selectNomPrenom: new FormControl(),
      selectProspect: new FormControl(),
      prospect_id: new FormControl(),
    });

    if (this.telCountry === 'FR'  ) {
      this.countryLanguage = France.data;
      this.defaultCountry = {
        name: 'France',
        alpha2Code: 'FR',
        alpha3Code: 'FRA',
        numericCode: '250',
        callingCode: '+33',
      };
    } else if (this.telCountry === 'TN') {
      this.countryLanguage = Tunis.data;
      this.defaultCountry = {
        name: 'Tunisie',
        alpha2Code: 'TN',
        alpha3Code: 'TN',
        numericCode: '',
        callingCode: '+216',
      };
    }else if (this.telCountry === 'MA'){
      this.countryLanguage = France.data;
      this.defaultCountry = {
        name: 'Maroc',
        alpha2Code: 'MA',
        alpha3Code: 'MAR',
        numericCode: '',
        callingCode: '+212',
      };
    }
    if (this.typeEntity !== 'o') {
      this.apiProspectsService.AllowToupdate$.subscribe((value) => {
        this.allowed_to_update = value;
      });
    }
    if (this.allowed_to_update === undefined) {
      this.allowed_to_update = true;
    }
    /* if( this.schemaValue?.schema){
  this.schemaValue?.schema?.properties?.forEach((element) => {
    const item: BodyConf = new BodyConf();
    item.name = element.name;
    item.title = element.title;
    item.disabled = false;
    this.listkeys.push(item);
  });
} */
  }

  buildForm(): void {
    if (this.schemaValue != null) {
      this.prospectFormnew = this.schemaValue;
      this.options = {};
      if (this.prospectFormnew?.schema) {
        const data = this.prospectFormnew;
        const formPropreties: any = data?.schema?.properties;

        if (formPropreties?.length > 0) {
          this.isfilled = true;
          this.loaderinfo = false;
          data.schema.properties = {};
          formPropreties.forEach((element) => {
            data.schema.properties[element.name] = element;
            this.model[element.name] = element.default;
          });
        } else if ( formPropreties && Object.keys(formPropreties).length) {
          this.isfilled = true;
          this.loaderinfo = false;
        } else {
          this.loaderinfo = false;
        }

        if (this.prospect?.form?.data)
          for (const [key, value] of Object.entries(this.prospect?.form?.data)) {
            if (value != null) this.model[key] = value;
          }
        if (data.schema.properties) {
          this.fields = [
            {
              ...this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.prospectFormnew?.schema))),
              /* hooks: {
                onInit: (field) => {
                  return field.formControl.valueChanges.pipe(
                    debounceTime(1000),
                    tap((value) => {
                      // this.updateProspect2(value);
                    })
                  );
                },
              }, */
            },
          ];
          /* if (this.prospect?.form.data && Object.keys(this.prospect?.form.data).length > 0) {
            this.model = this.prospect.form.data;
          } */
        }
        // this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(data.schema)))];
      }
    }
  }

  reset() {
    this.prospectClone = new Prospect();
    this.initFormProspect();
    this.updateProspect();
  }

  /* Convert Date from
  yyyy-MM-dd TO
  MM/dd/yyyy
  */
  formatDate(date: string) {
    if (date === undefined || date == null) {
      return null;
    }
    const DN = date.split('-');
    const date1 = DN.join('/');
    return date1;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  initFormProspect() {
    this.prospectForm = new FormGroup({
      id: new FormControl(this.prospect.id),
      prospects_id: new FormControl(this.prospect.prospects_id),
      post: new FormControl(this.prospect.post),
      ville: new FormControl(this.prospect.ville),
      situation: new FormControl(this.prospect.situation),
      adresse_mail: new FormControl(
        this.prospect.adresse_mail?.toLowerCase().includes('conjoint') ||
        this.prospect.adresse_mail?.toLowerCase().includes('enfant')
          ? ''
          : this.prospect.adresse_mail,
        [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
      ),
      civilite: new FormControl(this.prospect.civilite),
      name: new FormControl(this.prospect.name, [Validators.required]),
      surname: new FormControl(this.prospect.surname, [Validators.required]),
      DN: new FormControl(this.prospect.DN ? new Date(this.formatDate(this.prospect.DN)) : null),
      nb_enfants: new FormControl(this.prospect.nb_enfants),
      streetNumber: new FormControl(this.prospect.streetNumber),
      longitude: new FormControl(this.prospect.longitude),
      latitude: new FormControl(this.prospect.latitude),
      CP: new FormControl(this.prospect.CP),
      streetName: new FormControl(this.prospect.streetName),
      securite_sociale: new FormControl(this.prospect.securite_sociale),
      complement_adr: new FormControl(this.prospect.complement_adr),
      tel: new FormControl(this.prospect.tel),
      tel2: new FormControl(this.prospect.tel2),
      mobile: new FormControl(this.prospect.mobile),
      description: new FormControl(this.prospect.description),
      relation: new FormControl(this.prospect.relation),
      interlocuteur: new FormControl(this.prospect.interlocuteur),
      form: new FormControl(this.prospect.form),
      invalidForms: new FormControl(this.prospect.invalidForms),
    });

    if (this.allowed_to_update === false) {
      this.prospectForm.disable();
    }

    /*   this.schemaValue?.schema?.properties.forEach((element) => {
      let item: BodyConf= new BodyConf();
      item.name = element.name;
      item.title = element.title;
      item.disabled = false;
      this.listkeys.push(item);
    }); */
  }

  onChangeAdresseComplement(valeur: string) {
    this.AdresseComplement = valeur;
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.prospectForm.controls.CP.setValue(adress.postal_code);
    this.prospectForm.controls.streetName.setValue(adress.route);
    this.prospectForm.controls.streetNumber.setValue(adress.street_number);
    this.prospectForm.controls.ville.setValue(adress.locality);
    this.prospectForm.controls.longitude.setValue(adress.longitude);
    this.prospectForm.controls.latitude.setValue(adress.latitude);
    this.updateProspect2();
  }

  formModelChange(event): void {
    const changedValues = this.getChangedValues(this.prospect.form.data, event);

    for (const [key, value] of Object.entries(changedValues)) {
      const specificControl = this.fields[0].fieldGroup.find((element) => element.key === key);
      if (
        specificControl.type === 'boolean' ||
        (specificControl.focus === true && specificControl.type === 'multischema') ||
        (specificControl.focus === true && specificControl.type === 'enum') ||
        (specificControl.type === 'radio' && specificControl.form.controls[specificControl.key].dirty)
      ) {
        this.updateProspect2();
        break;
      }
    }
  }

  private getChangedValues(previousValue: any, currentValue: any): any {
    const changedValues = {};

    // Assuming both objects have the same structure
    for (const key in currentValue) {
      if (Object.prototype.hasOwnProperty.call(currentValue, key) && previousValue[key] !== currentValue[key]) {
        changedValues[key] = currentValue[key];
      }
    }

    return changedValues;
  }

  updateProspect2(value?) {
    // this.fillProspectInfo(this.prospectForm);
    if ((this.model != null && Object.keys(this.model).length > 0) || this.form.value) {
      const jsonnn = JSON.parse(JSON.stringify(this.form.value || this.model));
      const objCopy = { ...this.prospectForm.value.form };
      objCopy.data = jsonnn;
      this.prospectForm.controls.form.setValue(objCopy);
    }

    if (this.prospectForm.value.DN)
      this.prospectForm.controls.DN.setValue(this.datePipe.transform(this.prospectForm.value.DN, 'yyyy-MM-dd'));
    /* Toggle between interlocuteur and Main prospect */
    if (this.type === 1) {
      this.prospectForm.controls.relation.setValue('');
      if (this.isMainProspect) {
        this.prospectForm.controls.interlocuteur.setValue(true);
      } else {
        this.prospectForm.controls.interlocuteur.setValue(false);
      }
    } else {
      this.prospectForm.controls.interlocuteur.setValue(null);
      if (this.isMainProspect) {
        this.prospectForm.controls.relation.setValue('principal');
      }
    }

    this.prospectForm.controls.invalidForms.setValue(this.findInvalidControls());

    this.onUpdateProspect.emit(new Prospect(this.prospectForm.value));
  }

  fillProspectInfo(prospectForm: FormGroup): void {
    this.prospectClone.post = prospectForm.value.post;
    this.prospectClone.ville = prospectForm.value.ville;
    this.prospectClone.situation = prospectForm.value.situation;
    this.prospectClone.adresse_mail = prospectForm.value.adresse_mail;
    this.prospectClone.civilite = prospectForm.value.civilite;
    this.prospectClone.name = prospectForm.value.name;
    this.prospectClone.surname = prospectForm.value.surname;
    this.prospectClone.DN = prospectForm.value.DN;
    this.prospectClone.nb_enfants = prospectForm.value.nb_enfants;
    this.prospectClone.streetNumber = prospectForm.value.streetNumber;
    this.prospectClone.longitude = prospectForm.value.longitude;
    this.prospectClone.latitude = prospectForm.value.latitude;
    this.prospectClone.CP = prospectForm.value.CP;
    this.prospectClone.mobile = prospectForm.value.mobile;
    this.prospectClone.tel2 = prospectForm.value.tel2;
    this.prospectClone.tel = prospectForm.value.tel;
    this.prospectClone.complement_adr = prospectForm.value.complement_adr;
    this.prospectClone.streetName = prospectForm.value.streetName;
    this.prospectClone.description = prospectForm.value.description;
    this.prospectClone.relation = prospectForm.value.relation;
  }

  updateProspect() {
    this.prospectClone = new Prospect(this.prospectForm.value);
    if (this.model != null && Object.keys(this.model).length > 0) {
      const jsonnn = JSON.parse(JSON.stringify(this.model));
      const objCopy = { ...this.prospect.form };
      objCopy.data = jsonnn;
      this.prospectClone.form = objCopy;
    }

    if (this.prospectClone.DN) this.prospectClone.DN = this.datePipe.transform(this.prospectClone.DN, 'yyyy-MM-dd');
    /* Toggle between interlocuteur and Main prospect */
    if (this.type === 1) {
      this.prospectClone.relation = '';
      if (this.isMainProspect) {
        this.prospectClone.interlocuteur = true;
      } else {
        this.prospectClone.interlocuteur = false;
      }
    } else {
      this.prospectClone.interlocuteur = null;
      if (this.isMainProspect) {
        this.prospectClone.relation = 'principal';
      }
    }
    this.prospectClone.invalidForms = this.findInvalidControls();

    this.onUpdateProspect.emit(this.prospectClone);

    this.prospectClone = new Prospect();
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.prospectForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  seartch_str: string = '';
  formGroup: FormGroup;
  list_auto_fill_options: AutoFillEntity[] = [];
  defaultCountry: Country = {
    name: 'France',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    numericCode: '250',
    callingCode: '+33',
  };

  findByName() {
    if (this.seartch_str.length > 2) {
      const unsubscribeRechercheProspectGet = this.apiProspectsService
        .rechercheProspectGet(this.seartch_str)
        .pipe(
          tap(({ results }) => {
            this.list_auto_fill_options = results;
          })
        )
        .subscribe();
      this.listUnsubscribe.push(unsubscribeRechercheProspectGet);
    } else {
      this.list_auto_fill_options = null;
    }
  }

  onSubmit() {
    const unsubscribeselectProspectWithIdGet = this.apiProspectsService
      .selectProspectWithIdGet(this.formGroup.value.prospect_id)
      .subscribe((data: Prospect) => {
        this.prospect = data;
        this.initFormProspect();
      });
    this.listUnsubscribe.push(unsubscribeselectProspectWithIdGet);
  }

  prospectFinder() {
    this.onSelectToFillOldProspect.emit(this.index);
  }

  hasError($event: boolean) {}

  /**
   * Permets de retourner les informations bancaire saisie
   * @param $event
   */
  getBankInformation($event: BankInformation): void {
    this.prospect.information_bancaire = $event;
    this.onUpdateProspect.emit(this.prospect);
  }

  getNumber($event: any, type: string) {
    switch (type) {
      case 'mobile':
        this.prospectForm.controls.mobile.setValue($event);
        break;
      case 'tel':
        this.prospectForm.controls.tel.setValue($event);
        break;
      case 'tel2':
        this.prospectForm.controls.tel2.setValue($event);
        break;
      default:
        break;
    }
  }

  /**
   * Permets de détecter le pays
   * @param object
   */
  telInputObject(object): void {
    object.setCountry(object.j);
  }

  onCountrySelected($event: Country) {}

  isValid(field: string) {
    return this.prospectForm?.controls[field].invalid;
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  isDropdownVisible = true;

  stopClickPropagation(event: Event) {
    event.stopPropagation();
  }

  showhidePop() {
    this.isDropdownVisible = !this.isDropdownVisible;
    this.clearModal();
  }

  showhidePopfirst() {
    if (this.isDropdownVisible === false) {
      this.isDropdownVisible = true;
    }
  }

  formInfoHolder: chercheBody[] = [];

  onAdd() {
    const item = new chercheBody();
    this.formInfoHolder.push(item);
  }

  deletePrspect(i: number) {
    this.formInfoHolder.splice(i, 1);
    this.SelectedOptionDisabled();
    this.updateFilterBody();
  }

  SelectedOptionDisabled() {
    this.listkeys.forEach((item) => {
      const isDisabled = this.formInfoHolder.some((element) => element.key === item.name);
      item.disabled = isDisabled;
    });
  }

  setValues(index: number, property: string, event: any): void {
    const selectedValue = event;
    switch (property) {
      case 'key':
        this.formInfoHolder[index].key = selectedValue;
        break;
      case 'operateur':
        this.formInfoHolder[index].operateur = selectedValue;
        break;
      case 'value':
        this.formInfoHolder[index].value = selectedValue.target.value;
        break;
      default:
        break;
    }
    this.SelectedOptionDisabled();
    this.updateFilterBody();
  }

  updateFilterBody(): void {
    this.filterBody = new FilterBody();
    this.formInfoHolder.forEach((item) => {
      switch (item.key) {
        case 'name':
          this.filterBody.name = item.value;
          break;
        case 'surname':
          this.filterBody.surname = item.value;
          break;
        default:
          this.filterBody.info_com[item.key] = item.value;
          break;
      }
    });
  }

  clearModal() {
    this.formInfoHolder = [new chercheBody()];
  }

  OnOpen() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = {
      filterBody: this.filterBody,
    };
    this.dialogRef = this.dialog.open(ProspectFiltredListModalComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((data: Prospect) => {
      //  this.prospectPro.mainProspect = data;
      if (data) this.setData(data);
    });
  }

  loaderInfo: boolean = false;
  setData(data) {
    this.loaderInfo = true;
    this.prospectCLientService.GetProspectByID(data.id).subscribe(
      (data) => {
        this.loaderInfo = false;
        // this.prospectClone = new Prospect();
        this.prospect = data;

        this.model = this.prospect.form.data;
        this.form.patchValue(this.prospect?.form?.data);
        this.initFormProspect();

        this.updateProspect2();
        this.clearModal();
        this.SelectedOptionDisabled();
      },
      (erro) => {
        this.loaderInfo = false;
        this.SelectedOptionDisabled();
      }
    );
  }

  getConfigurationForm(): void {
    if (this.modeAjout) {
      if (this.schemaValue?.schema?.properties?.length > 0) {
        this.schemaValue.schema.properties.forEach((element) => {
          const item: BodyConf = new BodyConf();
          item.name = element.name;
          item.title = element.title;
          item.disabled = false;
          this.listkeys.push(item);
        });
      } else if (Object.entries(this.schemaValue?.schema?.properties).length > 0) {
        for (const [key, value] of Object.entries(this.schemaValue?.schema?.properties)) {
          const item: BodyConf = new BodyConf();
          item.name = this.schemaValue?.schema?.properties[key].name;
          item.title = this.schemaValue?.schema?.properties[key].title;
          item.disabled = false;
          this.listkeys.push(item);
        }
      }
    }
  }
}
