import { Validators, FormControl } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { tap, startWith, map, catchError } from 'rxjs/operators';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
 
import { LigneProduit } from 'src/app/entity/Opportunity/LigneProduit';
import { ApiContratsService } from 'src/app/services/ApiContrats/api-contrats.service';
import { ListTypeRendezVous } from 'src/app/entity/Opportunity/ListTypeRendezVous';
import {
  campagne,
  classe,
  cycle_de_vie,
  FilterOpportunite,
  Opportunite,
  tags,
  type_relation,
} from '../../../../../entity/Opportunity/FilterOpportunite';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';

@Component({
  selector: 'app-filtres-opportunites',
  templateUrl: './filtres-opportunites.component.html',
  styleUrls: ['./filtres-opportunites.component.css'],
})
export class FiltresOpportunitesComponent implements OnInit, OnChanges, OnDestroy {
  readonly rangeDateCreation = RangeDateComponent;

  readonly rangeDateObject = RangeDateComponent;

   
  filter: FilterOpportunite = new FilterOpportunite();
  filterOpportunite: Opportunite = new Opportunite();
 
  campagne: campagne[] = [];
  campagneId: string = '';
  cycle_de_vie: cycle_de_vie[] = [];
  classe: any[] = [];
  tags: tags[] = [];
  type_relation: type_relation[] = [];
  horsCamp: boolean = false;
  selectedcycle_de_vie: any[] = [];
  listCampagnes: any[] = [];
  listCampagneSelected: any[] = [];
  listProduct: any[] = [];
  listProductSelected: string[] = [];
  listLignesProduits: LigneProduit[] = [];
  listTypeOpportunite: ListTypeRendezVous[] = [];
  typeOpportuniteSelected: any[] = [];
  listClasses: any[] = [];
  listClassesSelected: any[] = [];
  tagsSelected: any[] = [];
  typeRelationSelected: any[] = [];
  typeRelation: any[] = [];
  filtre : any
  _unsubscribeAll: Subscription[] = [];

  date_creation: string =null;
  date_debut_objectif: string =null;
  date_fin: string = null;
  date_fin_objectif: string =null;

  // Mat Chips Produit
  ProductsFromCtrl: FormControl = new FormControl('', [Validators.required]);
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredProducts: Observable<LigneProduit[]>;
  ListProducts: string[] = [];
  allProducts: LigneProduit[] = [];

  @ViewChild('ProductsInput') ProductsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocompleteProduct: MatAutocomplete;

  // Mat Chips Campagne
  CampagnesFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCampagne: Observable<campagne[]>;
  ListCampagne: any[] = [];
  allCampagne: any[] = [];
  @ViewChild('picker') picker: MatDateRangePicker<any>;
  @ViewChild('Objectif') Objectif: MatDateRangePicker<any>;

  @ViewChild('CampagneInput') CampagneInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCampagne') matAutocompleteCampagne: MatAutocomplete;

  // Mat Chips Cycle De vie
  cycleDevieFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredcycleDevie: Observable<cycle_de_vie[]>;
  ListCycleDevie: string[] = [];
  allCycleDevie: any[] = [];

  @ViewChild('CycleDevieInput') CycleDevieInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCycleDevie') matAutocompleteCycleDevie: MatAutocomplete;

  // Mat Chips Classe
  classeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredClasse: Observable<classe[]>;
  ListClasse: string[] = [];
  allClasse: any[] = [];

  @ViewChild('classeInput') classeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoClasse') matAutocompleteClasse: MatAutocomplete;

  // Mat Chips Tags
  tagsFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredTags: Observable<tags[]>;
  ListTags: string[] = [];
  allTags: any[] = [];

  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTags') matAutocompleteTags: MatAutocomplete;

  // Mat Chips Tags NeContientPas
  tagsNeContientPasFromCtrl: FormControl = new FormControl('', [Validators.required]);
  ListTagsNeContientPas: string[] = [];
  filteredTagsNeContientPas: Observable<tags[]>;

  @ViewChild('tagsNeContientPasInput') tagsNeContientPasInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTagsNeContientPas') matAutocompleteTagsNeContientPas: MatAutocomplete;

  // Mat Chips Type Opp
  typeOppFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredTypeOpp: Observable<ListTypeRendezVous[]>;
  ListTypeOpp: string[] = [];
  allTypeOpp: ListTypeRendezVous[] = [];

  @ViewChild('typeOppInput') typeOppInput: ElementRef<HTMLInputElement>;
  @ViewChild('autotypeOpp') matAutocompletetypeOpp: MatAutocomplete;

  // Mat Chips Type Relation
  typeRelationFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredTypeRelation: Observable<type_relation[]>;
  ListTypeRelation: string[] = [];
  allTypeRelation: type_relation[] = [];

  @ViewChild('typeRelationInput') typeRelationInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTypeRelation') matAutocompleteTypeRelation: MatAutocomplete;

  @Output() onPushFilterOpportunite: EventEmitter<Opportunite> = new EventEmitter();
  @Input() onRefreshFilterOpportunite: Opportunite;
  tagsSelectedContient: any[] = [];
  tagsSelectedNeContient: any[] = [];

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();

  constructor(
    private apiOpportuniteService: ApiOpportunitService,
    private apiContratsService: ApiContratsService,
    private chRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {
  
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  openDatePicker() {
    if (this.picker) {
      this.picker.open();
    }
 
  }

  openDateObjectif() {
  
    if (this.Objectif){
      this.Objectif.open();
    }
  }
  
  
  // Product ---------------------------------------------------------------------------------------------

  selectedProducts(event: MatAutocompleteSelectedEvent): void {
    if (!this.listProductSelected.includes(event.option.value)) {
    this.ListProducts.push(event.option.viewValue);
    this.listProductSelected.push(event.option.value);}
    this.ProductsInput.nativeElement.value = '';
    this.ProductsFromCtrl.setValue(null);
    this.EmitFilterOpportunite()
  }

  removeProducts(product: string): void {
    const index = this.ListProducts.indexOf(product);
    if (index >= 0) {
      this.ListProducts.splice(index, 1);
      this.listProductSelected.splice(index, 1);
    }
  }

  clearDateRange() {
    this.filterOpportunite.date_debut_objectif = null;
    this.filterOpportunite.date_fin_objectif = null;
    this.date_debut_objectif = null;
    this.date_fin_objectif = null;
    this.EmitFilterOpportunite()
  }

  clearDateCreation(){
    this.filterOpportunite.date_creation = null;
    this.filterOpportunite.date_fin = null;
    this.date_creation = null;
    this.date_fin = null;
    this.EmitFilterOpportunite()
  }

  private _filter(): any[] {
    let filterValue = this.ProductsFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allProducts.filter((product) => product.type.toLowerCase().includes(filterValue));
  }

  //  Campagne -------------------------------------------------------------------------------------

  selectedCampagne(event: MatAutocompleteSelectedEvent): void {
    if (!this.listCampagneSelected.includes(event.option.value)) {
    this.ListCampagne.push(event.option.viewValue);
    this.listCampagneSelected.push(event.option.value);}
    this.CampagneInput.nativeElement.value = '';
    this.CampagnesFromCtrl.setValue(null);
  }

  removeCampagne(Campagne: string): void {
    const index = this.ListCampagne.indexOf(Campagne);
    if (index >= 0) {
      this.ListCampagne.splice(index, 1);
      this.listCampagneSelected.splice(index, 1);
    }
  }

  private _filterCampagne(): campagne[] {
    let filterValue = this.CampagnesFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCampagne.filter((Campagne) => Campagne.libelle?.toLowerCase().includes(filterValue) );
  }

  // CycleDevie -----------------------------------------------------------------------------------------------

  selectedCycleDevie(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedcycle_de_vie.includes(event.option.value)) {
    this.ListCycleDevie.push(event.option.viewValue);
    this.selectedcycle_de_vie.push(event.option.value);}
    this.CycleDevieInput.nativeElement.value = '';
    this.cycleDevieFromCtrl.setValue(null);
    this.EmitFilterOpportunite()
  }

  removeCycleDevie(CycleDevie: string): void {
    const index = this.ListCycleDevie.indexOf(CycleDevie);
    if (index >= 0) {
      this.ListCycleDevie.splice(index, 1);
      this.selectedcycle_de_vie.splice(index, 1);
    }
  }

  private _filterCycleDevie(): cycle_de_vie[] {
    let filterValue = this.cycleDevieFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCycleDevie.filter((CycleDevie) => CycleDevie.libele.toLowerCase().includes(filterValue) );
  }

  // Classe -----------------------------------------------------------------------------------------------

  selectedClasse(event: MatAutocompleteSelectedEvent): void {
    if (!this.listClassesSelected.includes(event.option.value)) {
    this.ListClasse.push(event.option.viewValue);
    this.listClassesSelected.push(event.option.value);}
    this.classeInput.nativeElement.value = '';
    this.classeFromCtrl.setValue(null);
    this.EmitFilterOpportunite()
  }

  removeClasse(Classe: string): void {
    const index = this.ListClasse.indexOf(Classe);
    if (index >= 0) {
      this.ListClasse.splice(index, 1);
      this.listClassesSelected.splice(index, 1);
    }
  }

  private _filterClasse(): classe[] {
    let filterValue = this.classeFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allClasse.filter((Classe) => Classe.libele.toLowerCase().includes(filterValue));
  }

  // Tags Contient -----------------------------------------------------------------------------------------------

  selectedTags(event: MatAutocompleteSelectedEvent): void {
    if (!this.tagsSelectedContient.includes(event.option.value)) {
    this.ListTags.push(event.option.viewValue);
    this.tagsSelectedContient.push(event.option.value);}
    this.tagsInput.nativeElement.value = '';
    this.tagsFromCtrl.setValue(null);
    this.EmitFilterOpportunite()
  }

  removeTags(Tags: string): void {
    const index = this.ListTags.indexOf(Tags);
    if (index >= 0) {
      this.ListTags.splice(index, 1);
      this.tagsSelectedContient.splice(index, 1);
    }
    if (this.tagsSelectedContient.length ===0){
      this.filterOpportunite.desicion_contient= '='
      this.chRef.detectChanges();
    }
  }

  private _filterTags(): tags[] {
    let filterValue = this.tagsFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allTags.filter((Tags) => Tags.libelle.toLowerCase().includes(filterValue));
  }

  // Tags Ne Contient pas -----------------------------------------------------------------------------------------------

  selectedTagsNeContientPas(event: MatAutocompleteSelectedEvent): void {
    this.ListTagsNeContientPas.push(event.option.viewValue);
    this.tagsSelectedNeContient.push(event.option.value);
    this.tagsNeContientPasInput.nativeElement.value = '';
    this.tagsNeContientPasFromCtrl.setValue(null);
  }

  removeTagsNeContientPas(Tags: string): void {
    const index = this.ListTagsNeContientPas.indexOf(Tags);
    if (index >= 0) {
      this.ListTagsNeContientPas.splice(index, 1);
      this.tagsSelectedNeContient.splice(index, 1);
    }

  }

  private _filterTagsNeContientPas(): tags[] {
    let filterValue = this.tagsNeContientPasFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allTags.filter((Tags) => Tags.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  // Type Opp -----------------------------------------------------------------------------------------------

  selectedTypeOpp(event: MatAutocompleteSelectedEvent): void {
    if (!this.typeOpportuniteSelected.includes(event.option.value)) {
    this.ListTypeOpp.push(event.option.viewValue);
    this.typeOpportuniteSelected.push(event.option.value);}
    this.typeOppInput.nativeElement.value = '';
    this.typeOppFromCtrl.setValue(null);
    this.EmitFilterOpportunite()
  }

  removeTypeOpp(typeOpp: string): void {
    const index = this.ListTypeOpp.indexOf(typeOpp);
    if (index >= 0) {
      this.ListTypeOpp.splice(index, 1);
      this.typeOpportuniteSelected.splice(index, 1);
    }
  }

  private _filterTypeOpp(): ListTypeRendezVous[] {
    let filterValue = this.typeOppFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allTypeOpp.filter((typeOpp) => typeOpp.libelle.toLowerCase().includes(filterValue) );
  }

  // Type Relation -----------------------------------------------------------------------------------------------

  selectedTypeRelation(event: MatAutocompleteSelectedEvent): void {
    this.ListTypeRelation.push(event.option.viewValue);
    this.typeRelationSelected.push(event.option.value);
    this.typeRelationInput.nativeElement.value = '';
    this.typeRelationFromCtrl.setValue(null);
  }

  removeTypeRelation(typeRelation: string): void {
    const index = this.ListTypeRelation.indexOf(typeRelation);
    if (index >= 0) {
      this.ListTypeRelation.splice(index, 1);
      this.typeRelationSelected.splice(index, 1);
    }
  }

  private _filterTypeRelation(): type_relation[] {
    let filterValue = this.typeRelationFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allTypeRelation.filter((typeRelation) => typeRelation.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    this.getListLignesProduits();
    this.getTypeOpportunite();
    this.getDetailsFilter();
    this.filteredProducts = this.ProductsFromCtrl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => (product ? this._filter() : this.allProducts.slice()))
    );

    this.filteredCampagne = this.CampagnesFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Campagne: string | null) => (Campagne ? this._filterCampagne() : this.allCampagne.slice()))
    );

    this.filteredcycleDevie = this.cycleDevieFromCtrl.valueChanges.pipe(
      startWith(null),
      map((CycleDevie: string | null) => (CycleDevie ? this._filterCycleDevie() : this.allCycleDevie.slice()))
    );

    this.filteredClasse = this.classeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Classe: string | null) => (Classe ? this._filterClasse() : this.allClasse.slice()))
    );

    this.filteredTags = this.tagsFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Tags: string | null) => (Tags ? this._filterTags() : this.allTags.slice()))
    );

    this.filteredTagsNeContientPas = this.tagsNeContientPasFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Tags: string | null) => (Tags ? this._filterTagsNeContientPas() : this.allTags.slice()))
    );

    this.filteredTypeOpp = this.typeOppFromCtrl.valueChanges.pipe(
      startWith(null),
      map((typeOpp: string | null) => (typeOpp ? this._filterTypeOpp() : this.allTypeOpp.slice()))
    );

    this.filteredTypeRelation = this.typeRelationFromCtrl.valueChanges.pipe(
      startWith(null),
      map((typeRelation: string | null) => (typeRelation ? this._filterTypeRelation() : this.allTypeRelation.slice()))
    );

    this.filterOpportunite = this.onRefreshFilterOpportunite;
    this.filtre = localStorage.getItem('filtre');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
      this.filterOpportunite=this.filtre.filtre_opp
      this.date_creation= this.datePipe.transform(this.filterOpportunite.date_creation, 'yyyy-MM-dd');
      this.date_debut_objectif = this.datePipe.transform( this.filterOpportunite.date_debut_objectif, 'yyyy-MM-dd');
      this.date_fin = this.datePipe.transform( this.filterOpportunite.date_fin, 'yyyy-MM-dd');
      this.date_fin_objectif= this.datePipe.transform( this.filterOpportunite.date_fin_objectif, 'yyyy-MM-dd');
      const allApi : Observable<any>[] = [
        this.apiContratsService.listeAllProduits(),
        this.apiOpportuniteService.getListRDV(),
        this.apiOpportuniteService.getDetailsFilter()
      ]
      forkJoin(allApi)
          .pipe(
            catchError(error => {
              console.error('Error:', error);
              return [];
            })
          )
          .subscribe(responses => {
            this.EmitFilterOpportunite()
          });
      
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetOppForm(changes?.Reset?.currentValue);
  }

  resetOppForm(Reset) {

    if (Reset === true) {
      this.ListProducts = [];
      this.filterOpportunite.produit = this.listProductSelected = [];
      this.ListCampagne = [];
      this.filterOpportunite.campagne = this.listCampagneSelected = [];
      this.ListCycleDevie = [];
      this.filterOpportunite.cycle_de_vie = this.selectedcycle_de_vie = [];
      this.ListClasse = [];
      this.filterOpportunite.classe = this.listClassesSelected = [];
      this.filterOpportunite.desicion_contient = '=';
      this.ListTags = [];
      this.filterOpportunite.tags_contient = this.tagsSelectedContient = [];
      this.ListTagsNeContientPas = [];
      this.ListTypeOpp = [];
      this.filterOpportunite.type_opportuntie = this.typeOpportuniteSelected = [];
      this.ListTypeRelation = [];
      this.filterOpportunite.type_relation = this.typeRelationSelected = [];
      this.filterOpportunite.date_creation = null;
      this.filterOpportunite.date_fin = null;
      this.filterOpportunite.heure_creation = '';
      this.filterOpportunite.heure_fin = '';
      this.filterOpportunite.date_debut_objectif = null;
      this.filterOpportunite.date_fin_objectif = null;
      this.filterOpportunite.heure_debut_objectif = '';
      this.filterOpportunite.heure_fin_objectif = '';
      this.date_creation = null;
      this.date_debut_objectif = null;
      this.date_fin = null;
      this.date_fin_objectif = null;
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterOpportunite.emit(this.filterOpportunite);
      this.chRef.detectChanges();
    }
  }

  getListLignesProduits() {
    const unsubscribeLignedeProduit = this.apiContratsService
      .listeAllProduits()
      .pipe(
        tap(({ lignes_produits }) => {
          this.allProducts = lignes_produits;
          if (this.filtre) {
            if (this.filterOpportunite?.produit.length > 0) {
              this.ListProducts = this.allProducts
                .filter(produit => this.filterOpportunite.produit.includes(produit.id))
                .map(produitAff => {
                  this.listProductSelected.push(produitAff.id);
                  return produitAff.type;
                });
            }
      }
        })
      )
      .subscribe();
    this._unsubscribeAll.push(unsubscribeLignedeProduit);
  }

  getTypeOpportunite() {
    const unsubscribeOpportunite = this.apiOpportuniteService.getListRDV().subscribe((data: ListTypeRendezVous[]) => {
      this.allTypeOpp = data;
      if (this.filterOpportunite.type_opportuntie.length > 0) {
        this.ListTypeOpp = this.allTypeOpp
          .filter(rdv => this.filterOpportunite.type_opportuntie.includes(rdv.id))
          .map(rdvAff => {
            this.typeOpportuniteSelected.push(rdvAff.id);
            return rdvAff.libelle;
          });
      }
    });
    this._unsubscribeAll.push(unsubscribeOpportunite);
  }

  getDetailsFilter() {
    const unsubscribeOpportunite = this.apiOpportuniteService
      .getDetailsFilter()
      .pipe(
        tap(({ classe, cycle_de_vie, tags, type_relation, campagne }) => {
          this.allClasse = classe;
          this.allCycleDevie = cycle_de_vie;
          this.allTags = tags;
          this.allTypeRelation = type_relation;
          this.allCampagne = campagne;
          if (this.filtre) {
            if (this.filterOpportunite.campagne.length > 0) {
              this.ListCampagne = this.allCampagne
                .filter(cmp => this.filterOpportunite.campagne.includes(cmp.id))
                .map(cmpAff => {
                  this.listCampagneSelected.push(cmpAff.id);
                  return cmpAff.libelle;
                });
            }
            if (this.filterOpportunite.cycle_de_vie.length > 0) {
              this.ListCycleDevie = this.allCycleDevie
                .filter(cycle => this.filterOpportunite.cycle_de_vie.includes(cycle.id))
                .map(cycleAff => {
                  this.selectedcycle_de_vie.push(cycleAff.id);
                  return cycleAff.libele;
                });
            }
            if (this.filterOpportunite.classe.length > 0) {
              this.ListClasse = this.allClasse
                .filter(classe => this.filterOpportunite.classe.includes(classe.id))
                .map(classeAff => {
                  this.listClassesSelected.push(classeAff.id);
                  return classeAff.libele;
                });
            }
            if (this.filterOpportunite.tags_contient.length > 0) {
              this.ListTags = this.allTags
                .filter(cmp => this.filterOpportunite.tags_contient.includes(cmp.id))
                .map(cmpAff => {
                  this.tagsSelectedContient.push(cmpAff.id);
                  return cmpAff.libelle;
                });
            }

      }
        })
      )
      .subscribe();
    this._unsubscribeAll.push(unsubscribeOpportunite);
  }

  selectOptionTag(option: string): void {
    this.filterOpportunite.desicion_contient = option;
    this.chRef.detectChanges();

  }

  EmitFilterOpportunite() {
    if (this.tagsSelectedContient.length ===0){
      this.filterOpportunite.desicion_contient= '='
    }
    this.chRef.detectChanges();
    this.filterOpportunite.cycle_de_vie = this.selectedcycle_de_vie;

    this.filterOpportunite.tags_contient = this.tagsSelectedContient;


    this.filterOpportunite.campagne = this.listCampagneSelected;

    this.filterOpportunite.type_opportuntie = this.typeOpportuniteSelected;

    this.filterOpportunite.produit = this.listProductSelected;

    this.filterOpportunite.classe = this.listClassesSelected;

    this.filterOpportunite.type_relation = this.typeRelationSelected;

    this.filterOpportunite.date_creation = this.datePipe.transform(this.date_creation, 'yyyy-MM-dd');
    this.filterOpportunite.date_debut_objectif = this.datePipe.transform(this.date_debut_objectif, 'yyyy-MM-dd');
    this.filterOpportunite.date_fin = this.datePipe.transform(this.date_fin, 'yyyy-MM-dd');
    this.filterOpportunite.date_fin_objectif = this.datePipe.transform(this.date_fin_objectif, 'yyyy-MM-dd');
    this.onPushFilterOpportunite.emit(this.filterOpportunite);
  }
}
