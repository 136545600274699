
  <div class=" card bg-white border-0 p-3 customMargin" >
    <div class="card-doc-title ml-3 row">
      <i class="fa-solid fa-umbrella mr-2 fa-lg"></i>
        {{'languages.affaire.garanties' | translate}} 
    </div>
    <button type="button" (click)="openModalOrPopup()"  class="btn-document-add btn-position-add" [attr.data-title]="'languages.buttons.addGarantie' | translate" data-toggle="modal" data-target="#AjoutgarantieModal">
      <i class="fa-solid fa-plus fa-lg"></i>
    </button>

   


    
    <ng-container *ngIf="show_loading_list">

      <div class="row mb-3 mt-3">
          <div class="text-center m-auto ">
              <mat-spinner [diameter]="30"></mat-spinner>

          </div>
      </div>
  </ng-container>
  <ng-container *ngIf="!show_loading_list">
    <div class="my-3  scrollbar" [ngClass]="{'table-scroll': listeGarantieTicket?.length > 5 }">
      <table class="table" id="Table"   style="table-layout: fixed;
width: 100%;">
   <thead class="head-table sticky-header">
    <tr>
      <th> {{'languages.affaire.code' | translate}} </th>
      <th> {{'languages.alerts.Libelle' | translate}} </th>
      <th> {{'languages.affaire.capitaux' | translate}}  </th>
      <th> {{'languages.tarificateur.Franchise' | translate}}  </th>
      <th>  {{'languages.ticket.Carence' | translate}} </th>
      <th> {{'languages.garantie.nbrSinistre' | translate}} </th>
      <th>{{'languages.garantie.plafondAns' | translate}} </th>
      <th> {{'languages.garantie.plafondSinistre' | translate}} </th>
      <th> {{'languages.ticket.sousTicket' | translate}} </th>

    </tr>
</thead>
    <tbody class="body-table" *ngIf="listeGarantieTicket?.length>0">
      <tr *ngFor="let item of listeGarantieTicket ; let index = index">
        <td  > {{item.code_garantie}}</td>
          <td  style="width: 30%;" >{{item.label}}</td>
          
          <td  >{{item.CA? item.CA :"-" }}</td>
          <td  >{{item.franchise !==null? item.franchise :"-"}}</td>
          <td  >{{item.delai_carence !==null? item.delai_carence :"-"}}</td>
          <td  > {{item.nb_sinistre_ans !==null? item.nb_sinistre_ans :"-"}}</td>
          <td  >{{item.plafond_sinistre !==null? item.plafond_sinistre :"-"}}</td>
          <td  >{{item.plafond_sinistre_ans !==null ? item.plafond_sinistre_ans :"-"}}</td>


        



          
          <td>

            <!-- <button *ngIf="item.id != '' && item.id != undefined" type="button" class="btn-dt-link" (click)="NaviagateToTicketDetails(item.id)">
              <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
              Détails Ticket
            </button> -->

              <button  (click)="AddSingleGarantie(item)" class="btn-sous-ticket row justify-content-center  w-100" type="button" data-toggle="modal" data-target="#AjoutSousTicketModal" >
                 <div class="col-12 align-items-start px-0 py-1" ><i class="fa-solid fa-plus "></i> {{'languages.ticket.sousTicket' | translate}}</div>
                </button>



          </td>
      </tr>

  </tbody>
</table> 
<div *ngIf="listeGarantieTicket?.length < 1 " class="text-center font-weight-bold pt-1">
  <div class="col-12  d-flex justify-content-center">
      <img src="assets/icons/nodata.svg">

    </div>
    <div class="col-12  d-flex justify-content-center">
      <p class="noData">{{'languages.affaire.noDataAvailable' | translate}}</p>
    </div>
</div>
    </div>
  </ng-container>
    </div>


    
<div *ngIf="showModal">
  <div id="AjoutgarantieModal" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog model-lg modal-dialog-centered" role="document">
          <div class="modal-content ">
              <div class="modal-header d-flex justify-content-center align-items-center">
                  <h2 class="text-center justify-content-center title m-0 w-100" id="exampleModalLabel">{{'languages.buttons.ajoutGarantie' | translate}} </h2>
                  <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <div class="modal-body">
                  
                <div class="p-3 mb-3 scrollbar" [ngClass]="{'table-scroll': listeGarantieAffaire?.length > 10 }">
                  <table id="Table" class="table">
                    <thead class="head-table">
                      <tr>
                        <th>{{'languages.affaire.code' | translate}}</th>
                        <th>{{'languages.alerts.Libelle' | translate}}</th>
                        <th>{{'languages.listing.actions' | translate}} </th>
                      </tr>
                    </thead>
                    
                    <tbody class="body-table" >
                      <tr  *ngFor="let item of listeGarantieAffaire; let index = index"  >
                   
                        <td>{{ item.code }}</td>
                        <td>{{ item.nom }}</td>
                       
                          <td>
                            <img src="/assets/img/Groupe2.svg" role="button" *ngIf="!isSelected(index)" (click)="toggleSelection(index)">
                            <img src="/assets/img/Groupe1.svg" role="button" *ngIf="isSelected(index)" (click)="toggleSelection(index)">
                          </td>
                       
                   
                      </tr>
                    </tbody>
                  </table>
                  <ng-container *ngIf="loaderListAddGarantie">
                      <div class="col-12 text-center d-flex justify-content-center">
                          <mat-spinner [diameter]="20"></mat-spinner>
                      </div>
                  </ng-container>
                  <div *ngIf="listeGarantieAffaire?.length === 0 && !loaderListAddGarantie" class="row pt-2">
                
                    <div class="col-12  d-flex justify-content-center">
                      <img src="assets/icons/nodata.svg">
                    </div>
                    <div class="col-12  d-flex justify-content-center">
                      <p class="noData">{{'languages.affaire.noDataAvailable' | translate}}</p>
                   </div>
               
                </div>
              </div>
                  <div class="row d-flex justify-content-center mt-2">
                    <ng-container *ngIf="addGarantieLoader">
                      <div class="col-12 text-center d-flex justify-content-center">
                          <mat-spinner [diameter]="20"></mat-spinner>
                      </div>
                  </ng-container>
                          <button type="button" class="btn-dt-save-popup custemSize mr-2" 
                              data-dismiss="modal" (click)="addSelectedItems()">
                              <i class="fa-solid fa-circle-check fa-lg"></i>
                              {{'languages.buttons.ajouter' | translate}}
                          </button>

                          <button type="button" class="btn-dt-cancel-popup ml-2 custemSize" data-dismiss="modal"
                              aria-label="Close">
                              <i class="fa-solid fa-circle-xmark fa-lg fa-icon-style"></i>
                              {{'languages.buttons.annuler' | translate}}
                          </button>
                     
                         
                      
                  </div>

              </div>

          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="AjoutSousTicketModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 60rem; ">
          <div class="modal-body p-0">
              <app-add-tickets [id]="affaire_id" [addeditemGarantie]="addeditemGarantie" [type]="'a'" [idTicket]="ticket_id" [prospect]="prospect_id" [fromDetails]="fromDetails" [sousTicket]="true"></app-add-tickets>
                          </div>
      </div>
  </div>
</div>
