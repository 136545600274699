<div class="flex-column bg-white rounded-card">
    <div class="header-card-gestion py-3">
      <div class="col-3">
      <button type="button" class="btn-dt-add ml-4"  [attr.data-title]="'languages.garantie.addGroupGarantie' | translate" (click)=" goTofiche()">
        <i class="fa-solid fa-plus"></i> 
      </button>
    </div></div>
    <div class="p-3 mb-3" >
      <table id="Table" class="table">
        <thead class="head-table">
          <tr>

            <th>  {{'languages.listing.icon' | translate}}</th>
            <th>  {{'languages.alerts.Libelle' | translate}}</th>
            <th style="white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 200px;
            padding: 20px;
            margin: 0;
            resize: horizontal;" >  {{'languages.ticket.description' | translate}}</th>

          <th>  {{'languages.listing.Action' | translate}}</th>

          </tr>
        </thead>
        <tbody class="body-table">

          <tr  *ngFor="let item of garantie; let index = index">

            <td >
              <i class=" {{item?.icon}} " *ngIf="item && item?.icon && item.icon !== 'None'" ></i>
              <p *ngIf="!item.icon ||  item.icon =='None'">-</p>
             </td>
            <td>{{item.libelle}}</td>
            <td > {{item.description ? expandFileName(item.description) :"-" }}</td>

            <td>
              <button type="button" class="btn-dt-list"(click)="goToDetails(item) ">
              <img src="/assets/icons/edit-icon.svg" >
            </button>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

