import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketDetails } from 'src/app/entity/Affaires/Tickets';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { OpportunitiesComponent } from '../../../list-opportunite/opportunities.component';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
 
import { Subscription } from 'rxjs';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
})
export class TicketComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() listticketsexport: EventEmitter<any> = new EventEmitter();
  prospect_id: string = '';
   
  ticket_details: TicketDetails[] = [];
  show_loading_add: boolean = false;
  id: string;
  type: string = 'o';
  fromDetails: Boolean = true;
  unsbscribeSelectTicketsOpportunites: Subscription;
  allowed_to_update: any;
  navbarTxt:string="";
  constructor(
    private route: ActivatedRoute,
    private apiAffairesService: ApiAffairesService,
    private apiOpportunitService: ApiOpportunitService,
    private router: Router,
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private sharedMenuObserverService: SharedMenuObserverService,

    private translate : TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
    setTimeout(() => {
      this.ngOnInit();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.unsbscribeSelectTicketsOpportunites.unsubscribe();
  }

  ngOnInit(): void {

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.navbar.OppDetailsTickets
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.navbar.OppDetailsTickets
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
     });
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.prospect_id = this.opportunitiesDetailsComponent.id_prospect;
    this.id = this.opportunitiesDetailsComponent.opportunity_id;
    this.getListTickets(this.id);
  }

  getListTickets(id) {
    this.show_loading_add = false;
    this.unsbscribeSelectTicketsOpportunites = this.apiAffairesService
      .SelectTicketsOpportunites(id)
      .subscribe((data: TicketDetails[]) => {
        this.listticketsexport.emit(data);
        this.ticket_details = data.reverse();
        this.show_loading_add = true;
      });
  }

  NaviagateToOppoDetials(id: string) {
    this.router.navigate(['/opportunities/details/' + id + '/prospect']);
  }

  NaviagateToTicketDetails(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/ticketsandsupport/details/' + id]));
    window.open(url, '_blank');

  }

}
