<app-navbar [collapse]="sideBar_status"></app-navbar>
<div class="row" style="min-height: calc(100% - 130px); min-width: 700px; position: relative; top: 79px;">
  <div class="col" [ngStyle]="{'max-width':(sideBar_status)? '80px':'210px'}">
    <app-sidebar (sidebar_status)="changeSideBarStatus($event)"></app-sidebar>
  </div>
  <div class="col">
    <div class="row" style="height: 101%;">
      <div class="col">
        <div
          style="width: inherit; min-width: 975px; position: absolute; padding-top: 10px; padding-left: 10px;">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-center justify-content-center">
      <div class="col" style="position: fixed; bottom: 0px;background-color: white;  z-index: 4">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>

<div class="git-issue-bubble">
  <app-gitlab-issue-bulle></app-gitlab-issue-bulle>
</div>
