<div class="row">
    <div class="col">
        <div class="form-group">
            <div style="display: flex; gap: 10px;">
                <mat-form-field class="full-width mt-1" appearance="standard" style="flex: 1;">
                  <mat-label class="title">{{'languages.communication.icons' | translate}}</mat-label>
                  <input
                    matInput
                    #item
                    (ngModelChange)="onChange(item.value)"
                    type="text"
                    [(ngModel)]="searchText"
                    placeholder="Rechercher une icône"
                    (input)="onSearchChange($event.target.value)"
                    #searchInput="ngModel"
                    [required]="req"
                  />
                </mat-form-field>
                <mat-form-field class="icon-style-select mt-1" appearance="standard">
                  <mat-label class="title">Style</mat-label>
                  <mat-select [(ngModel)]="selectedStyle"  (selectionChange)="onStyleChange($event.value)">
                    <mat-option selected value="fa-solid">Solid</mat-option>
                    <mat-option value="fa-regular">Regular</mat-option>
                    <mat-option value="fa-light">Light</mat-option>
                    <mat-option value="fa-thin">Thin</mat-option>
                    <mat-option value="fa-duotone">Duotone</mat-option>
               
                  </mat-select>
                </mat-form-field>
              </div>
              
              
            <div *ngIf="  searchInput.invalid && (searchInput.dirty || searchInput.touched)" class="message-erreur">
                <div *ngIf="searchInput.errors.required">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    {{'languages.communication.iconsOblig' | translate}}
                </div>
            </div>
            <div *ngIf="  touched && (!searchInput.dirty && !searchInput.touched)" class="message-erreur">
                <i class="fa-solid fa-triangle-exclamation"></i>
                {{'languages.communication.iconsOblig' | translate}}
            </div>
            <ng-container *ngIf="loadingIcons">
                <div class="col-12">
                    <div class="spinner-style">
                        <mat-spinner [diameter]="15"></mat-spinner>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="filteredIcons?.length == 0 && searchText !== '' && !loadingIcons" class="message-erreur">
                <i class="fa-solid fa-triangle-exclamation"></i>
                {{'languages.communication.noIcons' | translate}}
            </div>

         

            <div class="row list-icon scrolling" *ngIf="filteredIcons?.length>0">
                <div class="col-1" style="margin: 5px;" *ngFor="let icon of filteredIcons">
                    <button type="button" class="btn-icon-style"
                        (click)="onSelectIcon( 'brands' ,icon.name)"
                        *ngIf="icon.styles.length==1">
                        <i  class="fa-brands fa-{{icon.name}} fa-xl"></i>
                        </button>
                    <button type="button" class="btn-icon-style"
                    (click)="onSelectIcon( selectedStyle ,icon.name)" *ngIf="icon.styles.length>1">               
                    <i   class="{{selectedStyle}} fa-{{icon.name}} fa-xl"></i>
                </button>
                </div>
            </div>
        </div>
    </div>
</div>
