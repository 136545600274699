import { environment } from 'src/environments/environment';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { ApiAdminService } from 'src/app/services/ApiAdmin/api-admin.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { Compagnie } from '../../../entity/Compagnie';
 
import { Location } from '@angular/common';
import swal from 'sweetalert2';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { error } from 'console';
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { FormlyFormOptions } from '@ngx-formly/core';
class formbuilder {
  data;
  schema;
}

@Component({
  selector: 'app-companies-fiche',
  templateUrl: './companies-fiche.component.html',
  styleUrls: ['./companies-fiche.component.css'],
})
export class CompaniesFicheComponent implements OnInit {
  Compagnie: Compagnie = new Compagnie();
  initialCompagnie: Compagnie = new Compagnie();
  ModeAjout: boolean = true;
  logo: any = null;
 
  alerts: any;
  buttons: any;
  navbarTxt: string = '';
  show_image: any = '../../../../assets/img/noimage.png';
  show_loading_add: boolean = false;
  list_invalid: any;
  list_invalid_string: string = '';
  id_comagnie: string = '';
  telCountry = environment.country;
  reset: boolean = false;
  keyFormCompagnie: string;
  model: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  form = new FormGroup({});
  formdataspecif: any;
  appear: boolean = false;
  myForm: FormGroup = new FormGroup({
    siren: new FormControl('', [Validators.required]),
    numo: new FormControl(''),
    raison: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    cp: new FormControl(''),
    ville: new FormControl(''),
    streetName: new FormControl(''),
    streetNumber: new FormControl(''),
    url: new FormControl('', Validators.pattern('https?://.+')),
    mail_production: new FormControl('', Validators.email),
    mail_commercial: new FormControl('', Validators.email),
    mail_sav: new FormControl(''),
    tel_production: new FormControl(''),
    tel_sav: new FormControl(''),
    tel_commercial: new FormControl(''),
  });

  constructor(
    private route: ActivatedRoute,
    private ApiAdminService: ApiAdminService,
    private router: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private routes: Router,
    private location: Location,
    private el: ElementRef,
    private apiConfigurationService: ApiConfigurationService,
    private formlyJsonschema: FormlyJsonschema,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
    });
    this.keyFormCompagnie = environment.keyFormCompagnie;

    this.route.paramMap.subscribe((params) => {
      this.showloader();
      if (params.get('id') !== 'Nouveau') {
        this.ModeAjout = false;
        this.Compagnie.siren = params.get('id');
        this.ApiAdminService.get_compagne_by_siren(params.get('id'))
          .pipe(
            tap(({ compagnie_details }) => {
              this.Compagnie = compagnie_details;
              if (
                !this.Compagnie.form ||
                Object.keys(this.Compagnie.form).length === 0 ||
                Object.keys(this.Compagnie.form.schema).length === 0 ||
                Object.keys(this.Compagnie.form.schema.properties).length === 0
              ) {
                this.getFormParamConfig();
              } else {
                this.appear = true;
              }
              this.form = new FormGroup({});
              if (this.Compagnie.form != null) {
                this.formdataspecif = this.Compagnie.form;
                this.options = {};
                if (this.Compagnie.form?.schema) {
                  const form: any = this.Compagnie.form?.schema?.properties;

                  this.Compagnie.form.schema.properties = {};
                  this.model = {};
                  if (form !== undefined) {
                    form.forEach((element) => {
                      this.Compagnie.form.schema.properties[element.name] = element;
                      if (this.model[element.name]) this.model[element.name] = element.default;
                    });
                  }
                  if (this.Compagnie.form?.data){
                  for (const [key, value] of Object.entries(this.Compagnie.form?.data)) {
                    if (value != null) this.model[key] = value;
                  }
                }
                  //  this.fields = [
                  //    this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.organisme.form.schema))),
                  //  ];
                  this.fields = [
                    {
                      ...this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.Compagnie.form.schema))),
                      hooks: {
                        onInit: (field) => {
                          return field.formControl.valueChanges.pipe(
                            debounceTime(1500),
                            tap((value) => {
                              this.model = value;
                            })
                          );
                        },
                      },
                    },
                  ];
                }
              }
              this.myForm.get('siren').setValue(this.Compagnie.siren);
              if (this.Compagnie.logo_cie !== '') this.show_image = this.Compagnie.logo_cie;
              Object.assign(this.initialCompagnie, this.Compagnie);

              this.hideloader();
            })
          )
          .subscribe();
      } else {
        this.getFormParamConfig();
        this.Compagnie.logo_cie = '../../../../assets/img/noimage.png';
        this.hideloader();
      }
    });
    if (this.ModeAjout) {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt = object.navbar.gestionCompagnies + ' ➜ ' + object.navbar.Ajouter;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
      this.translate.onLangChange.subscribe(() => {
        this.translate.get('languages').subscribe((object: any) => {
          this.navbarTxt = object.navbar.gestionCompagnies + ' ➜ ' + object.navbar.Ajouter;
          this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        });
      });
    } else {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt = object.navbar.gestionCompagnies + ' ➜ ' + object.navbar.Modifier;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
      this.translate.onLangChange.subscribe(() => {
        this.translate.get('languages').subscribe((object: any) => {
          this.navbarTxt = object.navbar.gestionCompagnies + ' ➜ ' + object.navbar.Modifier;
          this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        });
      });
    }
  }

  getFormParamConfig(): void {
    this.apiConfigurationService.getParamConfig(this.keyFormCompagnie).subscribe((Response) => {
      this.form = new FormGroup({});
      if (Response.value != null) {
        this.formdataspecif = Response.value;
        this.options = {};
        if (
          !this.Compagnie.form ||
          Object.keys(this.Compagnie.form).length === 0 ||
          Object.keys(this.Compagnie.form.schema.properties).length === 0
        ) {
          this.Compagnie.form={}
          this.appear = false;
        } else {
          this.appear = true;
        }
        if (Response.value.schema) {
          const form: any = Response.value.schema.properties;
          if (Response.value.schema?.properties.length > 0) {
            this.appear = true;
          } else {
            this.appear = false;
          }
          Response.value.schema.properties = {};
          this.model = {};
          form.forEach((element) => {
            Response.value.schema.properties[element.name] = element;
            if (this.model[element.name]) this.model[element.name] = element.default;
          });
          /*  for (const [key, value] of Object.entries(this.organisme.form.data.data)) {
               if (value != null)
                 this.model[key] = value
             } */
          this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(Response.value.schema)))];
        }
      } else if (Response.value != null) {
        this.options = {};
        this.formdataspecif = Response.value;

        if (Response.value.schema) {
          this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(Response.value.schema)))];
        }
        // this.model = this.organisme.form.data?.data;
      }
    });
  }

  retourToList() {
    this.routes.navigate(['/gestion-companies']);
  }

  clearForm() {
    if (this.ModeAjout) {
      this.reset = true;
      this.Compagnie = new Compagnie();
      this.initialCompagnie = new Compagnie();
      this.Compagnie.logo_cie = '../../../../assets/img/noimage.png';
      this.show_image = '../../../../assets/img/noimage.png';
      this.myForm.reset();
      Object.keys(this.myForm.controls).forEach((key) => {
        const control = this.myForm.get(key);
        control?.setValue(null);
        control?.markAsPristine();
      });
    } else {
      this.reset = true;
      Object.assign(this.Compagnie, this.initialCompagnie);
      this.myForm.get('siren').setValue(this.initialCompagnie.siren);

      if (this.initialCompagnie.logo_cie !== '') {
        this.show_image = this.Compagnie.logo_cie;
      } else {
        this.show_image = '../../../../assets/img/noimage.png';
      }
    }
  }

  alertSuccess(data, idCompagnie, modeedit) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: modeedit,
        denyButtonText: this.buttons.newCompagnie,
        denyButtonColor: '833626',
        cancelButtonText: this.buttons.modifyCompagnie,
        confirmButtonText: this.buttons.listCompagnies,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          this.routes.navigate(['/gestion-companies']);
        }
        if (result.isDismissed) {
          this.hideloader();
          if (this.ModeAjout) this.routes.navigate(['/gestion-companies/fiche/' + idCompagnie]);
        }
        if (result.isDenied) {
          this.hideloader();
          this.routes.navigate(['/gestion-companies/fiche/Nouveau']);
          location.reload();
        }
      });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      icon: 'warning',
      text: this.alerts.fillInputs,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: this.alerts.badHappened,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  getLogo(event: any) {
    if (event.target.files && event.target.files.length) {
      for (const file of event.target.files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            const height = img.naturalHeight;
            const width = img.naturalWidth;
            if (width <= 11168 && height <= 1170) {
              this.logo = event.target.files[0];
              this.Compagnie.logo_cie = this.logo;
              this.show_image = reader.result;
            } else {
              alert(this.alerts.tailleTropGrande + width + this.alerts.largeur + height);
            }
          };
        };
      }
    }
  }

  onSelectFiles(event: any) {}

  makeFormTouched() {
    this.myForm.get('siren').markAsTouched();
    this.myForm.get('numo').markAsTouched();
    this.myForm.get('tel_sav').markAsTouched();
    this.myForm.get('tel_commercial').markAsTouched();
    this.myForm.get('tel_production').markAsTouched();
    this.myForm.get('mail_sav').markAsTouched();
    this.myForm.get('mail_commercial').markAsTouched();
    this.myForm.get('mail_production').markAsTouched();
    this.myForm.get('streetNumber').markAsTouched();
    this.myForm.get('streetName').markAsTouched();
    this.myForm.get('ville').markAsTouched();
    this.myForm.get('cp').markAsTouched();
    this.myForm.get('type').markAsTouched();
    this.myForm.get('raison').markAsTouched();
  }

  EnregistrerCompanie() {
    // this.Compagnie.form = new formbuilder();
    // this.Compagnie.form.data = {};
    // this.Compagnie.form.data = this.model;
    if (this.model != null && Object.keys(this.model).length > 0) {
      const jsonnn = JSON.parse(JSON.stringify(this.form.value));
      const objCopy = { ...this.Compagnie.form };
      objCopy.data = jsonnn;
      this.Compagnie.form = objCopy;
    }

    this.makeFormTouched();
    if (this.myForm.invalid) {
      this.alertWarning(this.alerts.formulaireinvalide);
    } else {
      this.show_loading_add = true;
      const formData: FormData = new FormData();

      this.Compagnie.siren? formData.append('siren', this.Compagnie.siren):formData.append('siren', "");
      this.Compagnie.adresse? formData.append('adresse', this.Compagnie.adresse):formData.append('adresse', "");
      this.Compagnie.cp?formData.append('cp', this.Compagnie.cp) : formData.append('cp', "");
      this.Compagnie.streetName?formData.append('streetName', this.Compagnie.streetName): formData.append('streetName', "");
      this.Compagnie.streetNumber? formData.append('streetNumber', this.Compagnie.streetNumber) : formData.append('streetNumber', "");
      this.Compagnie.ville? formData.append('ville', this.Compagnie.ville) : formData.append('ville', "");
      this.Compagnie.blob ? formData.append('blob', this.Compagnie.blob) : formData.append('blob', "");
      this.Compagnie.denomination? formData.append('denomination', this.Compagnie.denomination):formData.append('denomination', "");
      this.Compagnie.mail_commercial ?formData.append('mail_commercial', this.Compagnie.mail_commercial):formData.append('mail_commercial', "");
      this.Compagnie.mail_production ? formData.append('mail_production', this.Compagnie.mail_production):formData.append('mail_production',"" );
      this.Compagnie.mail_sav? formData.append('mail_sav', this.Compagnie.mail_sav): formData.append('mail_sav', "");;
      this.Compagnie.matricule? formData.append('matricule', this.Compagnie.matricule):formData.append('matricule', "");
      this.Compagnie.tel_sav ? formData.append('tel_sav', this.Compagnie.tel_sav) : formData.append('tel_sav', "");
      this.Compagnie.tel_commercial ? formData.append('tel_commercial', this.Compagnie.tel_commercial):formData.append('tel_commercial', "");
      this.Compagnie.tel_production ? formData.append('tel_production', this.Compagnie.tel_production) :formData.append('tel_production', "")
      this.Compagnie.types? formData.append('types', this.Compagnie.types) :formData.append('types', "");
      this.Compagnie.url? formData.append('url', this.Compagnie.url) :formData.append('url', "");
      formData.append('logo_cie', this.logo);
      formData.append('form', JSON.stringify(this.Compagnie.form));
      if (this.ModeAjout) {
        this.ApiAdminService.Insert_compagnes(formData).subscribe(
          (Response) => {
            if (Response.message === 'insérer avec succès') {
              this.show_loading_add = false;
              this.alertSuccess(this.alerts.companieInsere, Response.siren, true);
            } else {
              this.show_loading_add = false;
              this.alertError(this.alerts.error);
            }
            this.show_loading_add = false;
          },
          () => {
            this.alertError(this.alerts.error);
            this.show_loading_add = false;
          }
        );
      } else {
        if (this.logo === null) {
          formData.append('logo_cie', '');
        }
        this.ApiAdminService.Update_compagnes(formData).subscribe(
          (Response) => {
            if (Response.message === 'mise a jour avec succès') {
              this.show_loading_add = false;
              this.alertSuccess(this.alerts.compagnieUpdated, Response.id, false);
            } else {
              this.show_loading_add = false;
              this.alertError(this.alerts.error);
            }
            this.show_loading_add = false;
          },
          () => {
            this.alertError(this.alerts.error);
            this.show_loading_add = false;
          }
        );
      }
    }
  }

  getCompangieForm(event: any): void {
    this.Compagnie.form = event;
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.Compagnie.ville = adress.locality;
    this.Compagnie.cp = adress.postal_code;
    this.Compagnie.streetName = adress.route;
    this.Compagnie.streetNumber = adress.street_number;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getNumber($event: any, type: string) {
    switch (type) {
      case 'telProd':
        this.Compagnie.tel_production = $event;
        break;
      case 'telsav':
        this.Compagnie.tel_sav = $event;
        break;
      case 'telcomm':
        this.Compagnie.tel_commercial = $event;
        break;
      default:
        break;
    }
  }

  numberlength(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else if (event.target.value.length === 8) {
      return true;
    } else if (event.target.value.length === 9) {
      return false;
    }
  }
}
