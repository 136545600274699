import { Component, Input, OnInit } from '@angular/core';
import { ApiNotificationService } from '../../services/ApiNotification/api-notification.service';
import { SharedMenuObserverService } from '../../services/SharedMenuObserver/shared-menu-observer.service';
 
import { ApiPaymentService } from '../../services/ApiPayment/api-payment.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationDate, Notifications } from 'src/app/entity/Generic/Notifications';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { UserAdmin } from 'src/app/entity/User/UserAdmin';
import { ApiLedgerService } from 'src/app/services/ApiLedger/api-ledger.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAdmin2 } from 'src/app/entity/User/UserAdmin2';
import { WebmailService } from 'src/app/services/ApiWebmail/webmail.service';
import { Mail } from 'src/app/entity/mail';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { tap } from 'rxjs/operators';
import swal from 'sweetalert2';
import { NotificationsNavbarService } from './../../services/notifications-navbar.service';
import { environment } from 'src/environments/environment';

moment.locale('fr');
export declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  menu_id: string;
  child: RouteInfo[];
  isNotActiveMenu: boolean;
}

export const ROUTES: RouteInfo[] = [];
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
   
  lu: boolean = false;
  alllu: boolean = false;

  languages: string[] = ['EN', 'FR'];
  selected_lang = '';
  @Input() collapse = false;
  user: UserAdmin2 = new UserAdmin2();
  payment_facture_url: SafeUrl;
  deposit_value: number = 0;
  bundleList: number[] = [5, 10, 50, 100, 200, 500];
  other_bundle: boolean = false;
  currentCurrency = environment.currentCurrency;
  notification: Notifications[] = [];
  notificationDate: NotificationDate = new NotificationDate();
  mesg: Mail[] = [];
  AllMesg: Mail[] = [];
  routes: RouteInfo[] = [];
  position: string;
  prepay: boolean = false;
  solde: number;
  // a
  now: any;
  lang:string='';
  configue: boolean = false;
  currentIcon: string = '';
  totalNotifNotViewed: any;
  configured: boolean = false;
  alerts: any;
  buttons: any;

  constructor(
    private apiNotificationService: ApiNotificationService,
    public sanitizer: DomSanitizer,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiPaymentService: ApiPaymentService,
    private apiUsersService: ApiUsersService,
    private apiSolde: ApiLedgerService,
    private translate: TranslateService,
    private datepipe: DatePipe,
    private router: Router,
    private apiWebmail: WebmailService,
    private route: ActivatedRoute,
    private notificationService: NotificationsNavbarService,
    private translateService: TranslateService

  ) {
    this.routes = ROUTES;
    this.lang = this.route.snapshot.queryParamMap.get('lang');

  }

  ngOnInit(): void {
    this.translateService.get('languages').subscribe((object: any) => {
      this.alerts=object.alerts
      this.buttons=object.buttons
    });
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('languages').subscribe((object: any) => {
        this.alerts=object.alerts
      this.buttons=object.buttons
      });
     });
  

// setting language 
    let selectedLang: string;

    const storedLang = localStorage.getItem('selected_language');
    if (storedLang) {
      selectedLang = storedLang;
    } else {
      const userLanguage = navigator.language;
      const parts = userLanguage.split('-');
      selectedLang = parts[0];
    }

    this.translate.setDefaultLang('fr');
    this.translate.use(selectedLang);

    this.selected_lang = selectedLang;
    this.sharedMenuObserverService.onUpdateMenu().subscribe((data) => {
      this.position = data;
    });
    this.sharedMenuObserverService.onUpdateIconMenu().subscribe((data) => {
      if (data == null) this.currentIcon = 'faChartLine';
      else this.currentIcon = data;
    });

    this.getListEmail();
    this.getNotificationList();
    this.getUserConnected();
    this.getsolde();
    this.notificationService.notifyUpdate$.subscribe(() => {
      // Call your API here to get notifications
      this.getNotificationList();
    });
  }

  /*   getListeForder(){ 
    this.apiWebmail.getListFolfder().subscribe((data) => {
      this.configue = data.is_configured;
    });
  
  } */

  // getDateNotification() {
  //   this.apiNotificationService.GetDateNotifications().subscribe((data: NotificationDate) => {
  //     this.notificationDate = data;

  //     this.getNotificationList();
  //   });
  // }

  getNotificationList() {
    this.apiNotificationService.getNotificationGet().subscribe((data) => {
      this.notification = data.notifications;
      this.totalNotifNotViewed = data.nbr_not_viewed;
      // this.notification.forEach(
      //   (notif) => (notif.date_notification = this.datepipe.transform(notif.date_notification, 'yyyy-MM-dd'))
      // );
    });
  }

  getFormattedTime(notification: any) {
    return moment(notification?.dateHeure, 'DD-MM-YYYY HH:mm:ssZ').local().format('DD-MM-YYYY HH:mm');
  }

  navigate(notif) {
    const baseUrl = window.location.origin;
    window.open(baseUrl + '/' + notif.url, '_blank');
    if (notif.status === 0) {
      this.apiNotificationService.updateStatusNotificationGet(notif.id).subscribe((data) => {
        this.getNotificationList();
      });
    }
  }

  createFacture() {
    this.apiPaymentService.getFacture(this.deposit_value * 100).subscribe((data) => {
      this.payment_facture_url = this.sanitizer.bypassSecurityTrustResourceUrl(data.payment_url);
    });
  }

  readen(value) {
    this.lu = value;
    this.alllu = true;
    $('#dropdownMailNotification').stop();
    event.stopImmediatePropagation();
  }

  getUserConnected() {
    this.apiUsersService.getUserWithIdGet().subscribe((data: UserAdmin2) => {
      this.user = data;
    });
  }

  alertError(data) {
    swal
      .fire({
        title: this.alerts.noConfigurationWebmail,
        text: data,
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonText: this.buttons.fermer,
        confirmButtonText: this.buttons.configurer,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.apiWebmail.tab = 3;
          this.router.navigate(['/profil']);
        }
      });
  }

  setDepositValue(number: number) {
    this.deposit_value = number;
  }

  clearPaymentFrame() {
    this.payment_facture_url = null;
  }

  expandName(name: string): string {
    name = name?.trim();
    if (name?.length < 25) {
      return name;
    } else {
      return name?.substring(0, 25) + '...';
    }
  }

  expandNameNofification(name: string): string {
    name = name?.trim();
    if (name?.length < 35) {
      return name;
    } else {
      return name?.substring(0, 40) + '...';
    }
  }

  expandNameEnp(name: string): string {
    name = name?.trim();
    if (name?.length < 10) {
      return name;
    } else {
      return name?.substring(0, 9) + '...';
    }
  }

  getsolde() {
    this.apiSolde.getSolde().subscribe((data) => {
      this.prepay = data.pre_pay;
      this.solde = data.balance;
    });
  }

  setLanguage(event) {

      this.selected_lang = event.toLowerCase();
      localStorage.setItem('selected_language', this.selected_lang);
      this.translate.use(event.toLowerCase());
  }

  logOut() {
    localStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  expandNameUser(name: string, surname: string): string {
    name = name?.trim();
    surname = surname?.trim();
    const allname = name + ' ' + surname;
    if (allname?.length < 25) {
      return allname;
    } else {
      return allname?.substring(0, 25) + '...';
    }
  }

  getInitialsName(nom, prenom): string {
    const firstInitial = nom.charAt(0).toUpperCase();
    const lastInitial = prenom.charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  }

  getListEmail() {
    this.apiWebmail
      .isConfigured()
      .pipe(
        tap((result) => {
          this.configured = result.is_configured;
          if (this.configured === true) {
            // this.getListeForder()
            this.apiWebmail.getListEmail().subscribe((data) => {
              if (data?.error) {
                console.log(data?.error);
              } else {
                this.AllMesg = data[0];
                this.AllMesg?.forEach((element) => {
                  element.time = moment(element.time, 'YYYYMMDD').fromNow();
                });
                this.mesg = this.AllMesg?.filter((element) => element.read === false);
              }
            });
          }
        })
      )
      .subscribe();
  }

  redirectioWeB(id) {
    this.router.navigate([`/webmail/`]);
  }
}
