import { Validators, FormControl } from '@angular/forms';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { Courrier } from 'src/app/entity/Maileva/FilterMaileva';
import { tap, startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subscription } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
 
import { DatePipe } from '@angular/common';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';

@Component({
  selector: 'app-filters-courrier',
  templateUrl: './filters-courrier.component.html',
  styleUrls: ['./filters-courrier.component.css'],
})
export class FiltersCourrierComponent implements OnInit, OnChanges, OnDestroy {
   
  filterCourrier: Courrier = new Courrier();
  listModeleSelected: any[] = [];
  listEnveloppeSelected: any[] = [];
  allModels: any[] = [];
  allInfo: any[] = [];
  listModels: any[] = [];
  listEmpty: any[] = [];
  modelSelectionnee: string = '';
  statut: any[] = [];
  statut_envoi: any[] = [];
  listTypeEnveloppeSelected: any[] = [];
  listTypeEnvoieSelected: any[] = [];
  listeOfUnsubscribeWebService: Subscription[] = [];
  readonly rangeDateCreation = RangeDateComponent;
  readonly rangeDateEnvoie = RangeDateComponent;
  readonly rangeDateLivraison = RangeDateComponent;
  readonly rangeDatePrevue = RangeDateComponent;
  
  date_debut_creation: string = null;
  date_debut_envoie: string =null;
  date_debut_livraison: string = null;
  date_debut_prevue: string = null;
  date_fin_creation: string = null;
  date_fin_envoie: string = null;
  date_fin_livraison: string = null;
  date_fin_prevue: string = null;
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushfilterCourrier: EventEmitter<Courrier> = new EventEmitter();

  @ViewChild('picker') picker: MatDateRangePicker<any>; 
  @ViewChild('Planifie') Planifie: MatDateRangePicker<any>; 

  @ViewChild('affranchissement') affranchissement: MatDateRangePicker<any>; 

  @ViewChild('prevue') prevue: MatDateRangePicker<any>; 




  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // Mat Chips Type d'envoie
  typeEnvoieFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredTypeEnvoie: Observable<any[]>;
  ListTypeEnvoie: string[] = [];
  type_envoie: any[] = [];

  @ViewChild('TypeEnvoieInput') TypeEnvoieInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTypeEnvoie') matAutocompleteTypeEnvoie: MatAutocomplete;
  filtre: any;
  constructor(
    private chRef: ChangeDetectorRef,
    private apiComminucationService: ApiComminucationService,
    private datePipe: DatePipe
  ) {}

  ngOnDestroy(): void {
    this.listeOfUnsubscribeWebService.forEach((element) => {
      element.unsubscribe();
    });
  }



  openDateCreation() {
    if (this.picker) {
      this.picker.open();
    }
 
  }

  clearDateCreation(){
    this.filterCourrier.date_debut_creation = null;
    this.filterCourrier.date_fin_creation = null;
    this.date_debut_creation = null;
     this.date_fin_creation = null;
     this.EmitFilterCourrier()
   }

   openDatePlanifie() {
    if (this.Planifie) {
      this.Planifie.open();
    }
 
  }

  clearDatePlanifie(){
    this.filterCourrier.date_debut_envoie = null;
    this.filterCourrier.date_fin_envoie = null;
    this.date_debut_envoie = null;
     this.date_fin_envoie = null;
     this.EmitFilterCourrier()
   }


   openDateAffranchissement() {
    if (this.affranchissement) {
      this.affranchissement.open();
    }
  }

  clearDateAffranchissement(){
    this.filterCourrier.date_debut_livraison = null;
    this.filterCourrier.date_fin_livraison = null;
    this.date_debut_livraison = null;
    this.date_fin_livraison = null;
     this.EmitFilterCourrier()
   }

   openDateprevue() {
    if (this.prevue) {
      this.prevue.open();
    }
  }

  clearDateprevue(){
    this.filterCourrier.date_debut_prevue= null;
    this.filterCourrier.date_fin_prevue = null;
    this.date_debut_prevue = null;
    this.date_fin_prevue = null;
     this.EmitFilterCourrier()
   }

  // Type d'envoie---------------------------------------------------------------------------------------------

  selectedTypeEnvoie(event: MatAutocompleteSelectedEvent): void {
    this.ListTypeEnvoie.push(event.option.viewValue);
    this.listTypeEnvoieSelected.push(event.option.value);
    this.TypeEnvoieInput.nativeElement.value = '';
    this.typeEnvoieFromCtrl.setValue(null);
  }

  removeTypeEnvoie(typeEnvoie: string): void {
    const index = this.ListTypeEnvoie.indexOf(typeEnvoie);
    if (index >= 0) {
      this.ListTypeEnvoie.splice(index, 1);
      this.listTypeEnvoieSelected.splice(index, 1);
    }
  }

  private _filterTypeEnvoie(): any[] {
    let filterValue = this.typeEnvoieFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.type_envoie.filter((typeEnvoie) => typeEnvoie.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    // this.getAllModels();
    this.filtre = localStorage.getItem('filtre');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
      this.filterCourrier = this.filtre?.courrier
      this.date_debut_creation = this.filterCourrier?.date_debut_creation;
      this.date_debut_envoie = this.filterCourrier?.date_debut_envoie
      this.date_debut_livraison = this.filterCourrier?.date_debut_livraison;
      this.date_debut_prevue = this.filterCourrier?.date_debut_prevue;
      this.date_fin_creation = this.filterCourrier?.date_fin_creation;
      this.date_fin_envoie = this.filterCourrier?.date_fin_envoie;
      this.date_fin_livraison = this.filterCourrier?.date_fin_livraison;
      this.date_fin_prevue = this.filterCourrier?.date_fin_prevue;
      this.EmitFilterCourrier()
    }
    this.getInformationDetailsLibelle();
    this.filteredTypeEnvoie = this.typeEnvoieFromCtrl.valueChanges.pipe(
      startWith(null),
      map((typeEnvoie: string | null) => (typeEnvoie ? this._filterTypeEnvoie() : this.type_envoie.slice()))
    );
  }

  getAllModels() {
    const unsubscribeGetModelsPost = this.apiComminucationService.GetModelsPost().subscribe((Response: any[]) => {
      this.allModels = Response;
      this.SelectListModels();
    });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetModelsPost);
  }

  getInformationDetailsLibelle() {
    const unsubscribe = this.apiComminucationService
      .GetDetailsLibellePost()
      .pipe(
        tap(({ statut, statut_envoi, type_envoie }) => {
          this.statut = statut;
          this.statut_envoi = statut_envoi;
          this.type_envoie = type_envoie;
        })
      )
      .subscribe();
    this.listeOfUnsubscribeWebService.push(unsubscribe);
  }

  SelectListModels() {
    this.listModels = Object.keys(this.allModels);
    this.listEmpty = this.allModels[this.modelSelectionnee];
  }

  getSelectedModels(event) {
    this.filterCourrier.modele = this.listModeleSelected = event.value;
  }

  getSelectedTypeEnvoi(event) {
    this.filterCourrier.enveloppe_courrier = this.listEnveloppeSelected = event.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetCourrierForm(changes.Reset.currentValue);
  }

  resetCourrierForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.listModeleSelected = [];
      this.listEmpty = [];
      this.filterCourrier.modele = this.listModeleSelected = [];
      this.ListTypeEnvoie = [];
      this.filterCourrier.type_envoie = this.listTypeEnvoieSelected = [];
      this.listEnveloppeSelected = [];
      this.filterCourrier.enveloppe_courrier = this.listEnveloppeSelected;
      this.filterCourrier.heure_debut_creation = '';
      this.filterCourrier.heure_fin_creation = '';
      this.filterCourrier.heure_debut_prevue = '';
      this.filterCourrier.heure_fin_prevue = '';
      this.filterCourrier.heure_debut_livraison = '';
      this.filterCourrier.heure_fin_livraison = '';
      this.filterCourrier.sent_from = [];
      this.filterCourrier.ref_preuve = '';
      this.filterCourrier.sujet = '';
      this.filterCourrier.ref_notif = '';
      this.filterCourrier.ref_client = '';
      this.filterCourrier.date_fin_envoie =null;
      this.filterCourrier.date_debut_envoie = null;
      this.filterCourrier.date_debut_creation = null;
      this.filterCourrier.date_fin_creation = null;
      this.filterCourrier.date_debut_livraison = null;
      this.filterCourrier.date_fin_livraison = null;
      this.filterCourrier.date_debut_prevue = null;
      this.filterCourrier.date_fin_prevue =null;
      this.filterCourrier.type_courrier = [];
      this.modelSelectionnee = '';
      this.date_debut_creation = null;
      this.date_debut_envoie = null;
      this.date_debut_livraison =null;
      this.date_debut_prevue = null;
      this.date_fin_creation = null;
      this.date_fin_envoie =null;
      this.date_fin_livraison = null;
      this.date_fin_prevue = null;

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushfilterCourrier.emit(this.filterCourrier);
    }
    this.SelectListModels();
  }

  EmitFilterCourrier() {
    this.filterCourrier.date_debut_creation = this.datePipe.transform(this.date_debut_creation, 'yyyy-MM-dd');
    this.filterCourrier.date_debut_envoie = this.datePipe.transform(this.date_debut_envoie, 'yyyy-MM-dd');
    this.filterCourrier.date_debut_livraison = this.datePipe.transform(this.date_debut_livraison, 'yyyy-MM-dd');
    this.filterCourrier.date_debut_prevue = this.datePipe.transform(this.date_debut_prevue, 'yyyy-MM-dd');
    this.filterCourrier.date_fin_creation = this.datePipe.transform(this.date_fin_creation, 'yyyy-MM-dd');
    this.filterCourrier.date_fin_envoie = this.datePipe.transform(this.date_fin_envoie, 'yyyy-MM-dd');
    this.filterCourrier.date_fin_livraison = this.datePipe.transform(this.date_fin_livraison, 'yyyy-MM-dd');
    this.filterCourrier.date_fin_prevue = this.datePipe.transform(this.date_fin_prevue, 'yyyy-MM-dd');

    this.filterCourrier.type_envoie = this.listTypeEnvoieSelected;
    this.onPushfilterCourrier.emit(this.filterCourrier);
  }
}
