<form (ngSubmit)="validatePdfMail(myForm.form.valid,myForm._directives)" #myForm="ngForm">
    <div class="card  border-0 p-4 text-center" *ngIf="!fromDetails"  [ngClass]="{'card-details':this.opportunity_id !=='' || (this.affaire_id !=='' && this.id_opportunite !=='')}">
        <div class="row details-header">
           
            <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
                <div class="icon-position">
                    <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
                </div>
            </button>
            <div class="row">
              <div class=" row details-spinner">
                <div class="col">
                  <ng-container *ngIf="show_loading_add">
                    <div class="col-12">
                      <div class="spinner-style">
                        <mat-spinner [diameter]="30"></mat-spinner>
                      </div>
                    </div>

                  </ng-container>
                </div>
                <div class="col mr-1" >
                  <button type="button" class="btn-load" (click)="resetForm()">
                    <i class="fa-solid fa-rotate-right"></i>
                  </button>
                </div>
              </div>
              <button class="btn-dt-save" type="submit" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
               {{'languages.buttons.enregistrer' | translate}}
              </button>
            </div>
          </div>

    </div>
    <div class="card">
        <mat-tab-group mat-align-tabs="center"  #tabs>
            <mat-tab label="{{'languages.communication.coordonnesDesti' | translate}}">
                <div class="row mt-2" >
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100 " appearance="standard" >
                                <mat-label>  {{'languages.communication.NomPrenomRaison' | translate}}</mat-label>
                                <input type="text" matInput name="nomprenom_dest" required [(ngModel)]="MailEva.nomprenom_dest" #nomprenom_dest="ngModel"
                                pattern="[a-z A-Z0-9]*">
                            </mat-form-field>
                            <div *ngIf="nomprenom_dest.invalid && (nomprenom_dest.dirty || nomprenom_dest.touched)" class="message-erreur">
                                <div *ngIf="nomprenom_dest.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                                   {{'languages.communication.checkNomDesti' | translate}}
                                </div>
                                <div *ngIf="nomprenom_dest.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                 {{'languages.communication.invalidNomDesti' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 pt-4">
                        <div class="row mt-2" >

                            <div class="col-2">
                                <div class="form-group">
                                       <label class="switch " >
                                        <input type="checkbox" name="verif" [(ngModel)]="MailEva.verif_bp_dest">
                                        <span class="slider round"></span>
                                      </label>
                                </div>
                            </div>  <div class="col-10">
                                <label class="title">{{'languages.communication.boitePostal' | translate}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                   <div *ngIf="MailEva.verif_bp_dest" class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard">
                                <mat-label> {{'languages.communication.Distribution' | translate}}</mat-label>
                                <input type="text" matInput required="" name="bp_adresse" [(ngModel)]="MailEva.bp_adresse" #bp_adresse="ngModel" placeholder="{{'languages.communication.formatBP' | translate}}"  pattern=".{0,38}">
                            </mat-form-field>
                            <div *ngIf="bp_adresse.invalid && (bp_adresse.dirty || bp_adresse.touched)" class="message-erreur">
                                <div *ngIf="bp_adresse.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                                   {{'languages.communication.checkBoiteDesti' | translate}}
                                </div>
                                <div *ngIf="bp_adresse.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                  {{'languages.communication.checkBoiteDestiLength' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col pl-4" *ngIf="MailEva.verif_bp_dest" >
                        <mat-form-field class="full-width w-100" appearance="standard" >
                            <mat-label> {{'languages.communication.CEDEX' | translate}}</mat-label>
                            <input type="number" matInput name="bp_compl_ville_dest" min="0" onkeypress="return event.charCode >= 48" [(ngModel)]="MailEva.bp_compl_ville_dest" (ngModelChange)="controleCedexDest($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-auto-complete-adresses [isReadOnlyAdresse]="allowed_to_update" (onChangeAdresse)="onChangeAdresseSaisieAutoDest($event)"></app-auto-complete-adresses>
                </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col">
                                <mat-form-field class="full-width w-100" appearance="standard" >
                                    <mat-label> {{'languages.prospect.Ville' | translate}}</mat-label>
                                    <input type="text" matInput required name="ville_dest" [(ngModel)]="MailEva.ville_dest" (ngModelChange)="controleVilleDest($event)" #ville_dest="ngModel">
                                </mat-form-field>
                            </div>

                        </div>

                        <div *ngIf="ville_dest.invalid && (ville_dest.dirty || ville_dest.touched)" class="message-erreur">
                            <div *ngIf="ville_dest.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                              {{'languages.communication.villeDestiOblig' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard" >
                                <mat-label>{{'languages.prospect.CodePostal' | translate}}</mat-label>


                                <input type="number" min="1" step="1" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" type="number" matInput required name="postal" (ngModelChange)="getNumber($event)" [(ngModel)]="MailEva.postal_code_post" #postal="ngModel"
                                    pattern=".{5,5}">
                            </mat-form-field>
                            <div *ngIf="postal.invalid && (postal.dirty || postal.touched)" class="message-erreur">
                                <div *ngIf="postal.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{'languages.communication.postalCodeDestiOblig' | translate}}
                                </div>
                                <div *ngIf="postal.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                     {{'languages.communication.postalCodeDestiLength' | translate}}
                                </div>
                                <div *ngIf="forbiddenFirstCode && forbiddenSecondCode">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                     {{'languages.communication.postalCodeDesti97' | translate}}
                                </div>
                                <div *ngIf="forbiddenFirstCode && forbiddenSecondCode">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{'languages.communication.postalCodeDestiNotAutorise' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard" >
                                <mat-label> {{'languages.communication.rueNUM' | translate}}</mat-label>
                                <input type="text" matInput required name="donn_prospect" [(ngModel)]="MailEva.donn_prospect" #donn_prospect="ngModel" pattern=".{0,38}">
                            </mat-form-field>
                            <div *ngIf="donn_prospect.invalid && (donn_prospect.dirty || donn_prospect.touched)" class="message-erreur">
                                <div *ngIf="donn_prospect.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{'languages.communication.rueNUMDestiOblig' | translate}}
                                </div>
                                <div *ngIf="donn_prospect.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{'languages.communication.rueNUMDestiLength' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard" >
                                <mat-label>{{'languages.prospect.fullAdress' | translate}}</mat-label>
                                <input type="text" matInput name="complement_adr_dest" [(ngModel)]="MailEva.complement_adr_dest" #complement_adr_dest="ngModel" >
                            </mat-form-field>
                            <div *ngIf="complement_adr_dest.invalid && (complement_adr_dest.dirty || complement_adr_dest.touched)" class="message-erreur">
                                <div *ngIf="complement_adr_dest.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                             {{'languages.communication.checkfullAdress' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="message" class="message-erreur">
<i class="fa-solid fa-triangle-exclamation"></i>
                     {{'languages.communication.checkiNPUTS' | translate}}
                </div>
            </mat-tab>
            <mat-tab label=" {{'languages.communication.coordonneExp' | translate}}">
                <div class="row mt-2" >
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard" >
                                <mat-label> {{'languages.communication.fullName' | translate}}</mat-label>
                                <input type="text" matInput name="nomprenom_exp" required [(ngModel)]="MailEva.nomprenom_exp" #nomprenom_exp="ngModel" pattern="[a-zA-Z ]*">
                            </mat-form-field>
                            <div *ngIf="nomprenom_exp.invalid && (nomprenom_exp.dirty || nomprenom_exp.touched)" class="message-erreur">
                                <div *ngIf="nomprenom_exp.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                                     {{'languages.communication.nomPrenomExpOblig' | translate}}
                                </div>
                                <div *ngIf="nomprenom_exp.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                     {{'languages.communication.nomPrenomExpLength' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6 col-sm-12 pt-4">
                        <div class="row mt-2" >
                             <div class="col-6">
                                <label class="title"> {{'languages.communication.boitePostal' | translate}}</label>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                       <label class="switch space-switch" >
                                        <input type="checkbox" name="verif"  name="verifP" [(ngModel)]="MailEva.verif_bp_exp"  >
                                        <span class="slider round"></span>
                                      </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div *ngIf="MailEva.verif_bp_exp" class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard">
                                <mat-label>{{'languages.communication.Distribution' | translate}}</mat-label>
                                <input type="text" matInput name="bp_adresse_exp" placeholder="{{'communication.formatBP' | translate}}"  required="" [(ngModel)]="MailEva.bp_adresse_exp" #bp_adresse_exp="ngModel" pattern=".{0,38}">
                            </mat-form-field>
                            <div *ngIf="bp_adresse_exp.invalid && (bp_adresse_exp.dirty || bp_adresse_exp.touched)" class="message-erreur">
                                <div *ngIf="bp_adresse_exp.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                                     {{'languages.communication.checkDistribution' | translate}}
                                </div>
                                <div *ngIf="bp_adresse_exp.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{'languages.communication.checkdistributionLength' | translate}}
                                </div>
                            </div>
                        </div>
                    </div><div class="col pl-4" *ngIf="MailEva.verif_bp_exp" >
                        <mat-form-field class="full-width w-100" appearance="standard" >
                            <mat-label> {{'languages.communication.CEDEX' | translate}}</mat-label>
                            <input type="text" matInput name="bp_compl_ville_exp" min="0" onkeypress="return event.charCode >= 48" (ngModelChange)="controleCedexExp($event)" [(ngModel)]="MailEva.bp_compl_ville_exp">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard" >
                                <mat-label> {{'languages.prospect.email' | translate}}</mat-label>
                                <input type="text" matInput name="email_exp" required [(ngModel)]="MailEva.email_exp" #email_exp="ngModel" >
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12" >
                        <app-auto-complete-adresses [isReadOnlyAdresse]="allowed_to_update" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                        </app-auto-complete-adresses>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col">
                                <mat-form-field class="full-width w-100" appearance="standard" >
                                    <mat-label>{{'languages.prospect.Ville' | translate}}</mat-label>
                                    <input type="text" matInput name="ville_exp" required name="ville_exp" (ngModelChange)="controleVilleExp($event)" [(ngModel)]="MailEva.ville_exp" #ville_exp="ngModel">
                                </mat-form-field>
                            </div>

                        </div>
                        <div *ngIf="ville_exp.invalid && (ville_exp.dirty || ville_exp.touched)" class="message-erreur">
                            <div *ngIf="ville_exp.errors.required">
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                 {{'languages.communication.villeExpOblig' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard" >
                                <mat-label>{{'languages.prospect.CodePostal' | translate}}</mat-label>
                                <input type="number" matInput name="postal_code_exp" [(ngModel)]="MailEva.postal_code_exp" required #postal_code_exp="ngModel" pattern=".{5,5}" (ngModelChange)="getNumberExp($event)">
                            </mat-form-field>
                            <div *ngIf="postal_code_exp.invalid && (postal_code_exp.dirty || postal_code_exp.touched)" class="message-erreur">
                                <div *ngIf="postal_code_exp.errors.required">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{'languages.communication.checkPostalCodeExpOblig' | translate}}
                                </div>
                                <div *ngIf="postal_code_exp.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                   {{'languages.communication.postalCodeDestiLength' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard">
                                <mat-label>{{'languages.communication.rueNUM' | translate}}</mat-label>
                                <input type="text" matInput required name="donn_exp" [(ngModel)]="MailEva.donn_exp" #donn_exp="ngModel" pattern=".{0,38}">
                            </mat-form-field>
                            <div *ngIf="donn_exp.invalid && (donn_exp.dirty || donn_exp.touched)" class="message-erreur">
                                <div *ngIf="donn_exp.errors.required">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                  {{'languages.communication.rueNUMExpOblig' | translate}}
                                </div>
                                <div *ngIf="donn_exp.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                     {{'languages.communication.rueNUMExpLength' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width w-100" appearance="standard" >
                                <mat-label> {{'languages.prospect.fullAdress' | translate}}</mat-label>
                                <input type="text" matInput name="complement_adr_exp" [(ngModel)]="MailEva.complement_adr_exp" pattern=".{0,100}" #complement_adr_exp="ngModel">
                            </mat-form-field>
                            <div *ngIf="complement_adr_exp.invalid && (complement_adr_exp.dirty || complement_adr_exp.touched)" class="message-erreur">
                                <div *ngIf="complement_adr_exp.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                     {{'languages.communication.checkfullAdressExp' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="messageExp" class="message-erreur">
                    <i class="fa-solid fa-triangle-exclamation"></i>
              {{'languages.communication.checkiNPUTSExp' | translate}}
                </div>
                <div class="form-group" *ngIf="forbiddenFirstCodeExp && forbiddenSecondCodeExp" class="message-erreur">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                   {{'languages.communication.postalCodeDestiNotAutorise' | translate}}
                </div>

            </mat-tab>
            <mat-tab label="{{'languages.role.Parametres' | translate}}">
                <!-- <div class="row mt-2" >
                    <div class="col-12 mb-5" >
                        <h3>Sélectionner un modèle:</h3>
                        <ng-container *ngIf="show_loading">
                            <div class="col-12">
                              <div class="spinner-style">
                                <mat-spinner [diameter]="30"></mat-spinner>
                              </div>
                            </div>
                          </ng-container>
                        <div class="row" *ngIf="!show_loading">
                            <div class="col text-center">
                                <ul>
                                    <li class="nav-item dropdown" *ngFor="let model of communicationModel; let i=index">
                                        <ng-container *ngIf="model.childs.length>0">
                                            <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle btn-style">
                      {{expandFileName(model.nom)}}
                    </a>
                                            <ng-container *ngTemplateOutlet="dropdownList;context:{'menu':model}"></ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="model.childs.length<1">
                                            <ng-container *ngTemplateOutlet="defaultBtnModel;context:{'menu':model}"></ng-container>
                                        </ng-container>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="row" *ngIf="communicationModel.length<1 && !show_loading" >
                            <div class="col-12 text-center"  >
                            <p  class="small font-weight-bold"  >Pas de modèle disponible </p>
                            </div>
                        </div>

                    <div class="row " *ngIf="libelle_modele !=='' ">
                        <div class="col-12" >
                            <label> Modèle : {{libelle_modele}}</label>
                        </div>
                    </div>
                    </div>




                    <div class="col-12">
                    </div>
                    <div class="col-12">
                        <mat-tab-group (selectedTabChange)="preview($event)" mat-align-tabs="center" >
                            <mat-tab label="Écrire">
                                <ckeditor [config]="config" class="w-100 mt-3"  [editor]="Editor" name="modele_reel" [(ngModel)]="MailEva.modele_reel" (change)="getValueFromEditor($event)"></ckeditor>
                            </mat-tab>
                            <mat-tab label="Aperçu" *ngIf="!apercu">

                                <ng-container *ngIf="show_loading_prev">
                                    <div class="col-12">
                                      <div class="spinner-style">
                                        <mat-spinner [diameter]="30"></mat-spinner>
                                      </div>
                                    </div>

                                  </ng-container>


                                <ckeditor [config]="config"  class="w-100 mt-3"  name="preview" [(ngModel)]="contentPreview" [editor]="Editor" [disabled]="true"></ckeditor>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div> -->
                <div class="row pt-2">
                    <div class="col-12 d-flex justify-content-center">
                        <div  style="width:50%">
                            <mat-form-field class="full-width w-100 " appearance="standard" >
                                <mat-label> {{'languages.alerts.subject' | translate}}</mat-label>
                                <input type="text" matInput name="subject" required [(ngModel)]="MailEva.subject" #subject="ngModel" >
                            </mat-form-field>
                            <div *ngIf="subject.invalid && (subject.dirty || subject.touched)" class="message-erreur">
                                <div *ngIf="subject.errors.required">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                   {{'languages.communication.checkSubject' | translate}}
                                </div>
                                <div *ngIf="subject.errors.pattern">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{'languages.communication.checkInvalidSubject' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="row pt-2" >
                    <div class="col-6 p-2"  >
                        <mat-form-field appearance="fill" class="w-100" >
                            <mat-select name="envelope" [(ngModel)]="MailEva.type_envelope" placeholder="{{'languages.communication.typeEnveloppe' | translate}}" >
                                <mat-option value="C4">
                                    {{'languages.communication.c4' | translate}}
                                </mat-option>
                                <mat-option value="C6">
                                    {{'languages.communication.C6' | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="col-6 p-2 d-flex justify-content-center "  >
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-select name="envelope" [(ngModel)]="MailEva.type_envoie" placeholder="{{'languages.communication.typeEnvoie' | translate}}">
                                <mat-option *ngFor="let type of type_envoie" value="{{type.value}}">
                                    {{type.libelle}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row pt-2">
                    <div class="col-4  d-flex justify-content-center pt-4"   >

                        <div class="form-group mt-3">
                        <label class="switch" >
                            <input type="checkbox"  name="couleur"  [(ngModel)]="MailEva.couleur"  >
                            <span class="slider round"></span>
                          </label>
                          </div>  <label id="signature3 " class="mt-3"> {{'languages.communication.couleur' | translate}}</label>
                    </div>
                    <div class="col-4 d-flex justify-content-center pt-4  "  >

                        <div class="form-group mt-3">
                        <label class="switch" >
                            <input type="checkbox"   name="recto-verso"  [(ngModel)]="MailEva.recto_verso"  >
                            <span class="slider round"></span>
                          </label>
                          </div> <label id="signature1" class="mt-3"> {{'languages.communication.ImprimrectoVerso' | translate}}</label>
                    </div>
                    <div class="col-45 d-flex justify-content-center pt-4 "  >

                        <div class="form-group mt-3">
                        <label class="switch" >
                            <input type="checkbox"  (change)="onChangeSendNow()" name="send" [(ngModel)]="send_now">
                            <span class="slider round"></span>
                          </label>
                          </div><label id="signature2"  class="mt-3" > {{'languages.communication.sendNow' | translate}}</label>
                    </div>
                    <div class="col-12  p-2 d-flex justify-content-center "  *ngIf="!send_now">

                        <mat-form-field appearance="fill"  >
                            <mat-label> {{'languages.opportunite.details.date' | translate}}</mat-label>
                            <input matInput [matDatepicker]="pickerDebut" name="datepalanifier" [min]="getTodayDate()" [ngModel]="datepalanifier | date:'yyyy-MM-dd'" [value]="datepalanifier" (ngModelChange)="datepalanifier = $event">
                            <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDebut></mat-datepicker>


                        </mat-form-field>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                      <div class="fileContainer borderr p-3">
                        <div class="row">
                          <p class="card-doc-title ml-1">
                            {{'languages.communication.joinFiles' | translate}}
                          </p>
                          <button type="button"  (change)="fileBrowseHandler($event.target?.files)" for="audioDropRef2" class="btn-document-add  ajoutBtnDoc" [attr.data-title]="'languages.document.addFile' | translate">
                            <input type="file" hidden #fileDropRef2 id="audioDropRef2" accept="application/pdf" multiple  total-max-size="2000" />
                            <label for="audioDropRef2" class="add-file">
                                <i class="fa-solid fa-paperclip fa  fa-lg iconAjout "></i>
                            </label>
                          </button>
                        </div>
                        <div class="row scrollbar fileContainerChild">


                                <div class="col-md-4  " *ngFor="let file of files; let index=index">
                                    <div class=" col-12  fileComment py-2 m-2  d-flex  align-items-center " >
                                    <button  type="button" (click)="deleteFile(index)" class="deleteFile">
                                      <img  src='../../../assets/icons/deleteIcon.svg'>
                                      </button>
                               
                                    <img  src='../../../assets/icons/FileIcon.svg'>
                                        
                                          
                                   <span class="file-name  links ml-2">{{expandName(file.name)}}  </span>
                               </div>
                                </div>
                           
        
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer"  *ngIf="fromDetails" >
                    <div class="row w-100 d-flex justify-content-center" >
                        <div class="col-6 d-flex justify-content-center">
                            <ng-container *ngIf="show_loading_add">
                              
                                  <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                  
                                </div>

                        </ng-container>
                        </div>
                        <div class="row w-100 d-flex justify-content-center">
                   
                            <button class="btn-dt-save-popup mt-1 mr-2" type="submit" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                                {{'languages.buttons.enregistrer' | translate}}
                            </button>

                            <button class="btn-dt-cancel-popup mt-1 ml-2" type="button" (click)="resetForm()" #closebutton data-dismiss="modal">
                                <i class="fa-solid fa-circle-xmark  fa-lg mr-1"></i>
                                {{'languages.buttons.annuler' | translate}}
                            </button>
                   
                     
                        </div>
                    </div>
                    </div>

            </mat-tab>
        </mat-tab-group>
    </div>
    <ng-template #dropdownList let-menuList="menu">
        <ul class="dropdown-menu border-0 shadow">
            <ng-container *ngFor="let menu of menuList.childs">
                <ng-container *ngIf="menu.childs.length>0">
                    <ng-container *ngTemplateOutlet="subMenu;context:{'menu':menu}"></ng-container>
                </ng-container>
                <ng-container *ngIf="menu.childs.length<1">
                    <ng-container *ngTemplateOutlet="subElement;context:{'menu':menu}"></ng-container>
                </ng-container>
            </ng-container>
        </ul>
    </ng-template>

    <ng-template #subElement let-element="menu">
        <li><a (click)="closeAllDropDownElements($event);
                  getInfoModel(element);
                  setComValue(element.id);setModelValue(element.id, element.nom)" class="dropdown-item">{{element.nom}}
    </a>
        </li>
    </ng-template>

    <ng-template #subMenu let-element="menu">
        <li class="dropdown-submenu">
            <a (click)="showSubMenu($event)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-item dropdown-toggle">{{element.nom}}</a>
            <ng-container *ngTemplateOutlet="dropdownList;context:{'menu':element}"></ng-container>
        </li>
    </ng-template>
    <ng-template #defaultBtnModel let-model="menu">
        <a (click)="getInfoModel(model);setModelValue(model.id, model.nom)" aria-haspopup="true" aria-expanded="false" class="nav-link  btn-style dropbtn">
    {{expandFileName(model.nom)}}
  </a>
    </ng-template>
</form>
