<div class="flex-column bg-white rounded-card">
  <div class="header-card-gestion py-3">
    <!-- routerLink="fiche/Nouveau"  -->
    <div class="col-3">
    <button type="button" class="btn-dt-add ml-4"  [attr.data-title]="'languages.garantie.addGarantie' | translate" (click)=" goTofiche()">
      <i class="fa-solid fa-plus "></i>
    </button>
  </div>
</div>
  <div class="p-3 mb-3">
    <table id="Table" class="table" style="table-layout: fixed;
    width: 100%;">
      <thead class="head-table">
        <tr>

          <th>   {{"languages.listing.icon" | translate}}</th>
          <th>   {{"languages.garantie.nomGarantie" | translate}}</th>
          <th>   {{"languages.garantie.codeGarantie" | translate}}</th>
          <th>   {{"languages.garantie.unit" | translate}}</th>
          <th>  {{"languages.garantie.Cotisation" | translate}}</th>
          <th>  {{"languages.ticket.description" | translate}}</th>
          <th>  {{"languages.listing.Action" | translate}}</th>

        </tr>
      </thead>
      <tbody class="body-table">
        <tr *ngFor="let item of garantie; let index = index"> 
            <td *ngIf="item?.icon &&  item?.icon !== 'None'">
              <i  class=" {{item?.icon}} " ></i>
            </td>
          <td *ngIf="!item?.icon || item?.icon === 'None'  "> -</td>
          <td>{{ item.nom }}</td>
          <td>{{ item.code }} </td>
          <td>{{ item.unite ? item.unite : "-" }}</td>
          <td *ngIf="item.cotisation === 1">
            <span>
              <img src="assets/icons/check-circle.svg">
            </span>
          </td>
          <td *ngIf="item.cotisation === 0">
            <span>
              <img src="assets/icons/times-icon.svg">
            </span>
          </td>
          <td *ngIf="item.description !==''"> {{expandFileName(item.description) }}</td>
          <td *ngIf="item.description ==''"> -</td>
          <td>
            <button type="button" class="btn-dt-list" (click)="goToDetails(item) ">
              <img src="/assets/icons/edit-icon.svg">
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
