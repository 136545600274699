import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ApiOpportunitService } from '../../../../../services/ApiOpportunite/api-opportunite.service';
import { HistoriqueOpportunity } from '../../../../../entity/HistoriqueOpportunity';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
 
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { MatSort } from '@angular/material/sort';
import { UniformiserDateToLocal } from '../../../../../shared/Utils/sharedFunctions';
import { Subscription } from 'rxjs';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { AccessList } from 'src/app/entity/accessList';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.css'],
})
export class HistoriqueComponent implements OnInit, OnDestroy, AfterViewInit {
  historiques: HistoriqueOpportunity[] = [];
  dataTable: any;
   
  displayedColumns: string[] = ['date', 'commentaire', 'user', 'commentaireD'];
  dataSource: MatTableDataSource<HistoriqueOpportunity>;
  loading = true;
  unsubscribeGetHistorique: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  getDates: any;
  formatDates: any;
  unsbscribeGetHistorique: Subscription;
  allowed_to_update: any;
  accessList : AccessList ;
  histAccess: boolean;
  histCommAccess: boolean;
  navbarTxt:string="";
  constructor(
    private opportunitiesDetails: OpportunitiesDetailsComponent,
    private apiOpportunitService: ApiOpportunitService,
    private chRef: ChangeDetectorRef,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiAuthentificationService: ApiAuthentificationService,
private translate : TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnDestroy(): void {
    this.unsbscribeGetHistorique.unsubscribe();
  }

  ngOnInit(): void {
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.histAccess = this.accessList.historique;
      this.histCommAccess =this.accessList.historique_communications;
      });
    this.allowed_to_update = this.opportunitiesDetails.allowed_to_update;
    this.getHistorique();

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.navbar.OppDetailsHistorique
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.navbar.OppDetailsHistorique
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
     });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getHistorique() {
    this.loading = true;
    this.unsbscribeGetHistorique = this.apiOpportunitService
      .GetHistorique(this.opportunitiesDetails.opportunity_id)
      .subscribe((data: HistoriqueOpportunity[]) => {
        this.historiques = data;
        this.historiques.forEach((element, index) => {
          this.historiques[index].date = UniformiserDateToLocal(element.date);
        });

        this.loading = false;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.chRef.detectChanges();
      });
  }

  changeDate(date: string) {
    return date.substr(0, 10) + ' ' + date.substr(11, 8);
  }
}
