<div class="flex-column bg-white rounded-card">
  <div class="header-card-gestion py-3">
    <div class="col-3">
    <button type="button" class="btn-dt-add  ml-4" routerLink="fiche/Nouveau"  [attr.data-title]="'languages.companies.addCompagnie' | translate">
      <i class="fa-solid fa-plus "></i>
    </button>
  </div>
  </div>
  <div class="p-3 mb-3" >
    <table id="Table" class="table">
      <thead class="head-table">
        <tr>
          <th> {{"languages.prospect.Identifiant" | translate}}</th>
          <th> {{"languages.companies.Immatriculation" | translate}}  </th>
          <th> {{"languages.companies.Raisonsociale" | translate}} </th>
          <th>  {{"languages.listing.Type" | translate}}</th>
          <th>  {{"languages.listing.Action" | translate}}</th>
        </tr>
      </thead>
      <tbody class="body-table">
        <tr  *ngFor="let item of Companies; let index = index">
          <td>{{ item.siren }}</td>
          <td>{{ item.matricule }}</td>
          <td>{{ item.denomination }}</td>
          <td>{{ item.types }}</td>
          <td>
            <button type="button" class="btn-dt-list" routerLink="fiche/{{item.siren}}">
              <img src="/assets/icons/edit-icon.svg">


            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
